import { Dispatch } from 'redux';

import { LatestReponseWeights } from 'nhi.shared/dist/models/latest-response-weights';
import { EditPatientParams } from 'nhi.shared/dist/models/patient-edit';
import { PatientSession, PhotoReport } from 'nhi.shared/dist/models/patient-session';
import { PatientDetailsResponse } from 'nhi.shared/dist/models/patients-details';
import { RegisterPatientForm } from 'nhi.shared/dist/models/register-patient-form';
import { RegisterPatientResponse } from 'nhi.shared/dist/models/register-patient-response';
import { sortBy } from 'nhi.shared/dist/utils/array';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';
import { preparePatientRegistrationPayload } from '@utils/patient.utils';

import { showSnackbarAction } from '@store/actions/core/application.actions';
import { mapMeasurementsLimits } from '@store/mappers/measurements.mappers';
import { selectActiveCustomerId } from '@store/selectors/application.selectors';

import { ITEMS_PER_PAGE } from '@components/organisms/Pagination/Pagination';

export const GET_PATIENTS_LIST = 'GET_PATIENTS_LIST';
export const GET_PATIENTS_COUNT = 'GET_PATIENTS_COUNT';
export const GET_PATIENT_DETAILS = 'GET_PATIENT_DETAILS';
export const GET_PATIENT_SESSIONS = 'GET_PATIENT_SESSIONS';
export const GET_PATIENT_SESSION_WEIGHTS = 'GET_PATIENT_SESSION_WEIGHTS';
export const SIGN_PATIENT_SESSIONS = 'SIGN_PATIENT_SESSIONS';
export const EDIT_PATIENT = 'EDIT_PATIENT';
export const REGISTER_PATIENT = 'REGISTER_PATIENT';
export const GET_PHOTO_REPORT = 'GET_PHOTO_REPORT';
export const CLEAR_PHOTO_REPORT = 'CLEAR_PHOTO_REPORT';
export const GET_CARE_RECIPIENTS = 'GET_CARE_RECIPIENTS';
export const REMOVE_PATIENT = 'REMOVE_PATIENT';

const getPatientsListAction = createAction(GET_PATIENTS_LIST);
const getCareRecipientsAction = createAction(GET_CARE_RECIPIENTS);
const getPatientsCountAction = createAction(GET_PATIENTS_COUNT);
const getPatientDetailsAction = createAction(GET_PATIENT_DETAILS);
const getPatientSessionAction = createAction(GET_PATIENT_SESSIONS);
const getPatientSessionWeightsAction = createAction(GET_PATIENT_SESSION_WEIGHTS);
const signPatientSessionsAction = createAction(SIGN_PATIENT_SESSIONS);
const editPatientAction = createAction(EDIT_PATIENT);
const getPhotoReportAction = createAction(GET_PHOTO_REPORT);
const clearPhotoReportAction = createAction(CLEAR_PHOTO_REPORT);
const removePatientAction = createAction(REMOVE_PATIENT);

interface PaginatedResults {
  total: number;
  items: any[];
}

export const removePatient = (careRecipientId: string) => async (dispatch: Dispatch) => {
  try {
    await apiFetch(dispatch)<LatestReponseWeights>('/api/User/RemoveCareRecipient', {
      userId: careRecipientId
    });
    dispatch(showSnackbarAction({ message: 'Remove patient data success', type: 'success', duration: 2500 }));
    dispatch(removePatientAction({ careRecipientId }));
  } catch {
    dispatch(showSnackbarAction({ message: 'Remove patient data failed', type: 'error', duration: 2500 }));
    throw 'Get patient session';
  }
};

export const getPatientSessionWeights =
  (careRecipientId: string, customerId: string, careRecipientAccessToken?: string) =>
    async (dispatch: Dispatch) => {
      try {
        const config = !careRecipientAccessToken
          ? undefined
          : ({
            headers: {
              'Care-Recipient-Access-Token': careRecipientAccessToken
            }
          } as RequestInit);

        const { result } = await apiFetch(dispatch)<LatestReponseWeights>(
          '/api/response/getweightsforlatestresponses',
          {
            userId: careRecipientId,
            directoryId: customerId
          },
          config
        );

        dispatch(getPatientSessionWeightsAction({ latestResponseWeights: result, careRecipientId }));
      } catch {
        dispatch(
          showSnackbarAction({ message: 'Get Patient session weights failed', type: 'error', duration: 2500 })
        );
      }
    };

export const clearPhotoReport = () => (dispatch: Dispatch) => {
  dispatch(clearPhotoReportAction());
};

export const getPhotoReport = (photoReportId: string) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)<PhotoReport>('/api/photoreport/getphotoreport', {
      photoReportId
    });

    dispatch(getPhotoReportAction(result));
  } catch {}
};

export const getCareRecipients = (customerId: string) => async (dispatch: Dispatch) => {
  try {
    const data = await apiFetch(dispatch)('/api/user/GetAllCareRecipients', { customerId });
    dispatch(getCareRecipientsAction(data.result));
    return data.result;
  } catch {}
};

export const getPatientsList = (customerId: string, patientsPage: number) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)<PaginatedResults>(
      '/api/measurement/GetCustomerAllMeasurements',
      {
        customerId,
        takeSessions: 20,
        skip: patientsPage * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE
      }
    );

    const items = result?.items ?? [];

    dispatch(getPatientsCountAction({ [`${customerId}_count`]: result.total }));
    dispatch(getPatientsListAction({ [customerId]: items }));
    return items;
  } catch {}
};

export const getPatientDetails =
  (userId: string, customerId?: string, careRecipientAccessToken?: string) => async (dispatch: Dispatch) => {
    try {
      const config = !careRecipientAccessToken
        ? undefined
        : ({
          headers: {
            'Care-Recipient-Access-Token': careRecipientAccessToken
          }
        } as RequestInit);
      const { result } = await apiFetch(dispatch)<PatientDetailsResponse>(
        '/api/CareRecipient/GetCareRecipient',
        {
          userId,
          customerId
        },
        config
      );
      const details = { ...result, measurementLimits: mapMeasurementsLimits(result.measurementLimits) };
      dispatch(getPatientDetailsAction({ [userId]: details }));
    } catch {}
  };

export const getPatientSessions =
  (userId: string, sessionId?: string, careRecipientAccessToken?: string) => async (dispatch: Dispatch) => {
    try {
      const config = !careRecipientAccessToken
        ? undefined
        : ({
          headers: {
            'Care-Recipient-Access-Token': careRecipientAccessToken
          }
        } as RequestInit);
      const data = await apiFetch(dispatch)<PatientSession[]>(
        '/api/Session/GetSessionsWithMeasurements',
        {
          userId,
          sessionId
        },
        config
      );

      const sorted = sortBy(data.result, 'date', false);

      dispatch(getPatientSessionAction({ userId, data: sorted }));
    } catch {}
  };

export const signPatientSessions = (userId: string, sessionIds: string[]) => async (dispatch, getStore) => {
  const customerId = selectActiveCustomerId(getStore());

  try {
    const data = await apiFetch(dispatch)<any>('/api/Session/SignSessions', {
      customerId,
      userId,
      sessionIds
    });
    dispatch(signPatientSessionsAction({ userId, data: data.result.signedSessionIds, customerId }));
  } catch {}
};

export const editPatient = (params: EditPatientParams) => (dispatch) => {
  return apiFetch(dispatch)('/api/Person/UpdatePerson', params).then(({ result }) => {
    dispatch(editPatientAction(result));
  });
};

export const registerPatient = (patient: RegisterPatientForm, customerId: string) => async (dispatch) => {
  const request = preparePatientRegistrationPayload(patient, customerId);
  try {
    await apiFetch(dispatch)<RegisterPatientResponse>('/api/user/createcarerecipientuser', request);
  } catch {
    throw new Error('Cannot register a patient');
  }
};
