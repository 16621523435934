import { AdministratorCustomer } from 'nhi.shared/dist/models/administrator-customer';
import { Customers } from 'nhi.shared/dist/models/customer';
import { HealthPackage } from 'nhi.shared/dist/models/health-package';
import { Questionnaire } from 'nhi.shared/dist/models/questionnaire';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import {
  GET_ADMINISTRATOR_CUSTOMERS,
  GET_CUSTOMER,
  GET_ADMINISTRATOR_QUESTIONNAIRES,
  GET_ADMINISTRATOR_HEALTH_PACKAGES,
  UPDATE_CUSTOMER_SETTINGS,
  UPDATE_CUSTOMER_QUESTIONNAIRES,
  ADD_CUSTOMER,
  GET_QUESTIONNAIRE,
  CLEAR_QUESTIONNAIRE,
  QUESTIONNAIRE_REMOVED
} from '@store/actions/core/settings.actions';
import {
  UPDATE_MEASUREMENTS_LIMITS,
  REMOVE_MEASUREMENT_LIMIT
} from '@store/actions/patients/measurements.actions';

export interface Settings {
  customersList: AdministratorCustomer[];
  customersDetails: Customers;
  healthPackages: HealthPackage[];
  questionnaires: Questionnaire[];
  questionnaire?: Questionnaire;
}

export const initialState: Settings = {
  customersList: [],
  customersDetails: {},
  healthPackages: [],
  questionnaires: [],
  questionnaire: undefined
};

const settingsReducer = createReducer(initialState, {
  [ADD_CUSTOMER]: (state, customer) => ({
    ...state,
    customersList: [...state.customersList, customer]
  }),
  [GET_ADMINISTRATOR_CUSTOMERS]: (state, payload) => ({ ...state, customersList: payload }),
  [GET_CUSTOMER]: (state, payload) => ({
    ...state,
    customersDetails: {
      ...state.customersDetails,
      [payload.directoryId]: payload
    }
  }),
  [GET_ADMINISTRATOR_QUESTIONNAIRES]: (state, questionnaires) => ({
    ...state,
    questionnaires
  }),
  [CLEAR_QUESTIONNAIRE]: state => ({
    ...state,
    questionnaire: undefined
  }),
  [QUESTIONNAIRE_REMOVED]: (state, questionnaireId) => ({
    ...state,
    questionnaires: state.questionnaires.filter(x => x.questionnaireId != questionnaireId),
    questionnaire: state.questionnaire.questionnaireId == questionnaireId ? undefined : state.questionnaire
  }),
  [GET_QUESTIONNAIRE]: (state, questionnaire) => ({
    ...state,
    questionnaire
  }),
  [UPDATE_CUSTOMER_QUESTIONNAIRES]: (state, { customerId, questionnairesIds }) =>
    updateDirectory(state, customerId, { questionnaires: questionnairesIds }),
  [GET_ADMINISTRATOR_HEALTH_PACKAGES]: (state, healthPackages) => ({
    ...state,
    healthPackages
  }),
  [UPDATE_MEASUREMENTS_LIMITS]: (state, { directoryId, measurementLimits }) =>
    updateDirectory(state, directoryId, { measurementLimits }),
  [REMOVE_MEASUREMENT_LIMIT]: (state, { directoryId, measurementLimitId }) =>
    updateDirectory(state, directoryId, {
      measurementLimits: state.customersDetails[directoryId].measurementLimits.filter(
        m => m.measurementLimitId !== measurementLimitId
      )
    }),
  [UPDATE_CUSTOMER_SETTINGS]: (state, { directoryId, ...settings }) =>
    updateDirectory(state, directoryId, { settings })
});

export default settingsReducer;

const updateDirectory = (state: Settings, directoryId: string, update: any) => {
  return {
    ...state,
    customersDetails: {
      ...state.customersDetails,
      [directoryId]: {
        ...state.customersDetails[directoryId],
        ...update
      }
    }
  };
};
