import { Patients } from 'nhi.shared/dist/models/patient';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import {
  GET_PATIENTS_LIST,
  GET_PATIENTS_COUNT,
  GET_CARE_RECIPIENTS,
  REMOVE_PATIENT
} from '../../actions/patients/patients.actions';

export const initialState: Patients = {
  careRecipients: []
};

const patientsReducer = createReducer(initialState, {
  [GET_PATIENTS_LIST]: (state, payload) => ({ ...state, ...payload }),
  [GET_CARE_RECIPIENTS]: (state, payload) => ({ ...state, careRecipients: payload ?? [] }),
  [GET_PATIENTS_COUNT]: (state, payload) => ({ ...state, ...payload }),
  [REMOVE_PATIENT]: (state, payload) => ({
    ...state,
    careRecipients: [...(state?.careRecipients ?? []).filter(x => x.userId !== payload.userId)]
  })
});

export default patientsReducer;
