import { Dispatch } from 'redux';

import {
  CreateHomeMonitoringBody,
  EditHomeMonitoringBody,
  HomeMonitoringQuestion
} from 'nhi.shared/dist/models/home-monitoring';
import { mapMeasurementsLimitsForSummary } from 'nhi.shared/dist/utils/home-monitoring';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_INITIAL_INFORMATION = 'GET_INITIAL_INFORMATION';
export const GET_HOME_MONITORING_PLAN = 'GET_HOME_MONITORING_PLAN';
export const GET_HOME_MONITORING_HISTORY = 'GET_HOME_MONITORING_HISTORY';

const getQuestionsAction = createAction(GET_QUESTIONS);
const getInitialInformationAction = createAction(GET_INITIAL_INFORMATION);
const getHomeMonitoringPlanAction = createAction(GET_HOME_MONITORING_PLAN);
const getHomeMonitoringHistoryAction = createAction(GET_HOME_MONITORING_HISTORY);

export const getQuestions = (diseases: string[]) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)<{ questions: HomeMonitoringQuestion[] }>(
      '/api/HomeMonitoring/GetQuestionsForDiseases',
      {
        diseases
      }
    );

    dispatch(getQuestionsAction(result));
    return result.questions;
  } catch {}
};

export const getInitialInformation = (patientId: string) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)('/api/HomeMonitoring/GetInitialInformationForUser', {
      userId: patientId
    });

    dispatch(getInitialInformationAction(result));
  } catch {}
};

export const getHomeMonitoringPlan = (homeMonitoringPlanId: string) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)('/api/HomeMonitoring/GetHomeMonitoringPlan', {
      homeMonitoringPlanId
    });
    dispatch(getHomeMonitoringPlanAction(result));
  } catch (error) {}
};

export const getHomeMonitoringHistory =
  (patientId: string, customerId: string) => async (dispatch: Dispatch) => {
    try {
      const { result }: any = await apiFetch(dispatch)('/api/HomeMonitoring/GetHomeMonitoringPlanHistory', {
        userId: patientId,
        directoryId: customerId
      });

      const homeMonitoringPlans = result.homeMonitoringPlans.map((r) => {
        return {
          ...r,
          measurementLimits: mapMeasurementsLimitsForSummary(r.measurementLimits)
        };
      });

      dispatch(getHomeMonitoringHistoryAction({ ...result, homeMonitoringPlans }));
    } catch (error) {}
  };

export const createHomeMonitoring = (data: CreateHomeMonitoringBody) => async (dispatch: Dispatch) => {
  try {
    await apiFetch(dispatch)('/api/HomeMonitoring/CreateHomeMonitoringPlan', data);
    await dispatch(getHomeMonitoringHistory(data.careRecipientId, data.directoryId) as any);

    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      message: error.message
    };
  }
};

export const editHomeMonitoring = (data: EditHomeMonitoringBody) => async (dispatch: Dispatch) => {
  try {
    await apiFetch(dispatch)('/api/HomeMonitoring/UpdateHomeMonitoringPlan', data);
    await dispatch(getHomeMonitoringHistory(data.careRecipientId, data.directoryId) as any);

    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      message: error.message
    };
  }
};
