import { UserRole } from 'nhi.shared/dist/models/user-role';

import { ConnectionType } from '@utils/useSocketStream';

import { isUserInRoles } from '../../auth';
import { RootStore } from '../reducers/root.reducer';

export const selectActiveCustomerId = (store: RootStore) => store.application.activeCustomer?.customerId;

export const selectCurrentUserId = (store: RootStore) => store.application.user?.userId;

export const selectCurrentUserData = (store: RootStore) => store.application.user;

export const selectIsUserSignedIn = (store: RootStore) => store.application.isSignedIn;

export const selectWebRtcConnection = (store: RootStore) =>
  store.communication.socketStreams[ConnectionType.webRtcHub];

export const selectNotificationsConnection = (store: RootStore) =>
  store.communication.socketStreams[ConnectionType.notifications];

export const selectIsVideChatAvailable = ({ application }: RootStore) => {
  if (application.patientAccessMode) {
    return false;
  }
  const customerId = application.activeCustomer.customerId;
  const customer = application.user?.customers.find((c) => c.customerId === customerId);
  return !!customer?.settings?.isVideoAvailable;
};
export const isCareRecipientSelector = (store: RootStore) => {
  return isUserInRoles([UserRole.CareRecipient], store.application.user.roles);
};

export const isCaregiverSelector = (store: RootStore) => {
  return isUserInRoles([UserRole.Caregiver], store.application.user.roles);
};

export const isAdministratorSelector = (store: RootStore) => {
  return isUserInRoles([UserRole.Administrator], store.application.user.roles);
};

export const isSuperAdministratorSelector = (store: RootStore) => {
  return isUserInRoles([UserRole.SuperAdministrator], store.application.user.roles);
};
