import { MdClose } from '@react-icons/all-files/md/MdClose';
import { MdFormatListNumbered } from '@react-icons/all-files/md/MdFormatListNumbered';
import { MdGroup } from '@react-icons/all-files/md/MdGroup';
import { MdPersonAdd } from '@react-icons/all-files/md/MdPersonAdd';
import { MdPowerSettingsNew } from '@react-icons/all-files/md/MdPowerSettingsNew';
import { MdSettings } from '@react-icons/all-files/md/MdSettings';
import { MdTimeline } from '@react-icons/all-files/md/MdTimeline';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Flex from 'nhi.shared/dist/components/atoms/Flex';
import Typography from 'nhi.shared/dist/components/atoms/Typography';
import theme from 'nhi.shared/dist/config/theme';
import { UserRole } from 'nhi.shared/dist/models/user-role';
import { Breakpoints, useMediaQuery } from 'nhi.shared/dist/utils/useMediaQuery';

import { signOut } from '@store/actions/core/application.actions';
import { selectCurrentUserData } from '@store/selectors/application.selectors';

import { isUserInRoles } from '../../../auth';
import { routes } from '../../../config/routes';

import styles from './styles.module.css';

interface MenuItem {
  label: string;
  link?: string;
  Icon: any;
  testId: string;
  requiredRoles?: UserRole[];
}

const MainMenu: MenuItem[] = [
  {
    label: 'Measurements',
    link: routes.measurements,
    Icon: MdTimeline,
    testId: 'measurements'
  },
  {
    label: 'My patients',
    link: routes.patients,
    Icon: MdGroup,
    testId: 'my-patients'
  },
  {
    label: 'Register patient',
    link: routes.patientRegistration,
    Icon: MdPersonAdd,
    requiredRoles: [UserRole.Caregiver],
    testId: 'register-patient'
  },
  {
    label: 'Access logs',
    link: routes.accessLogs,
    Icon: MdFormatListNumbered,
    requiredRoles: [UserRole.SysAdmin],
    testId: 'access-logs'
  }
];

const BottomMenu: MenuItem[] = [
  {
    label: 'Settings',
    link: routes.settings,
    Icon: MdSettings,
    requiredRoles: [UserRole.Administrator, UserRole.SuperAdministrator],
    testId: 'settings'
  },
  {
    label: 'Sign out',
    link: routes.signOut,
    Icon: MdPowerSettingsNew,
    testId: 'sign-out'
  }
];

interface DrawerProps {
  onCeClick?: () => void;
  isDrawerOpen: boolean;
  toggleDrawer: (isOpen: boolean) => void;
}

const Drawer: React.FC<DrawerProps> = ({ onCeClick, isDrawerOpen, toggleDrawer }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUserData);
  const isTablet = useMediaQuery(Breakpoints.MD);
  const handleRedirect = (link: string) => (e: any) => {
    e.preventDefault();

    if (link === routes.signOut) {
      return dispatch(signOut);
    }
    toggleDrawer(false);
    router.push(link);
  };

  const handleCloseDrawer = () => toggleDrawer(false);

  const renderMenuItems = (items: MenuItem[]) =>
    items
      .map(({ label, link, Icon, requiredRoles, testId }) => {
        if (requiredRoles && !isUserInRoles(requiredRoles, userData?.roles)) {
          return undefined;
        }

        const isActive = router.pathname === link;

        return (
          <Link key={label} href={link}>
            <a
              className={clsx(styles.menuItem, {
                [styles.active]: isActive
              })}
              data-testid={`menu-${testId}`}
              onClick={handleRedirect(link)}
            >
              <Icon fontSize="35px" />
              <Typography
                style={{ marginLeft: 20 }}
                letterSpacing="1.07"
                color={isActive ? 'white' : 'gray3'}
              >
                {t<any>(label)}
              </Typography>
            </a>
          </Link>
        );
      })
      .filter(Boolean);

  return (
    <Flex.Container
      direction="column"
      align="center"
      className={clsx(styles.container, { [styles.isOpen]: isDrawerOpen })}
    >
      {isTablet && (
        <MdClose
          onClick={handleCloseDrawer}
          color={theme.white}
          fontSize="35px"
          className={styles.closeButton}
        />
      )}
      <Link href={routes.measurements}>
        <a className={styles.logoLink}>
          <img src="/images/logo.png" className={styles.logo} />
        </a>
      </Link>
      <Flex.Container className={styles.menu} direction="column" justify="space-between" flex="1 1 100%">
        <Flex.Item className={styles.menuGroup}>{renderMenuItems(MainMenu)}</Flex.Item>
        <Flex.Item className={styles.menuGroup}>{renderMenuItems(BottomMenu)}</Flex.Item>
      </Flex.Container>
      <span className={styles.ceWrapper} onClick={onCeClick}>
        <img src="/images/CE.svg" className={styles.ce} />
      </span>
    </Flex.Container>
  );
};

export default Drawer;
