import { CaregiverCustomers } from 'nhi.shared/dist/models/caregiver-customer';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import { GET_CAREGIVER_CUSTOMERS } from '../../actions/customers/caregiver-customers.actions';

export const initialState: CaregiverCustomers = {};

const conversationsReducer = createReducer(initialState, {
  [GET_CAREGIVER_CUSTOMERS]: (_, payload) => payload
});

export default conversationsReducer;
