import { HubConnectionState } from '@microsoft/signalr';
import { PropsWithChildren } from 'react';

import { ConnectionType, useSocketStream } from '@utils/useSocketStream';

import Communicator from './organisms/Communicator';

export const WithSockets = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [rtcConnection, state] = useSocketStream(ConnectionType.webRtcHub);
  useSocketStream(ConnectionType.notifications);
  return (
    <>
      {children}
      {rtcConnection && state === HubConnectionState.Connected && <Communicator />}
    </>
  );
};
