import { useRouter } from 'next/router';
import { useState } from 'react';

import Drawer from '@components/organisms/Drawer/Drawer';
import { HealfyInformationModal } from '@components/organisms/HealfyInformationModal';
import TopBar from '@components/organisms/TopBar/TopBar';

import { layoutRoutes } from '../config/routes';

const Layout = ({ children }) => {
  const router = useRouter();
  const [isOpen] = useState(true);
  const useLayout = layoutRoutes.includes(router.pathname);

  const [isCeModalOpen, setIsCeModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleCe = () => setIsCeModalOpen(!isCeModalOpen);
  const toggleDrawer = (isOpen: boolean) => setIsDrawerOpen(isOpen);

  return useLayout ? (
    <>
      <TopBar toggleDrawer={toggleDrawer}></TopBar>
      <Drawer onCeClick={toggleCe} isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer}></Drawer>
      <HealfyInformationModal isOpen={isCeModalOpen} onClose={toggleCe} />
      <main id="main" className="content">
        {children}
      </main>

      <style jsx>{`
        .content {
          width: calc(100% - ${isOpen ? 280 : 60}px);
          min-height: 100vh;
          padding: 180px 20px 20px;
          margin-left: auto;
          overflow: auto;
          position: relative;
        }

        @media (max-width: 768px) {
          .content {
            width: auto;
          }
        }
      `}</style>
    </>
  ) : (
    children
  );
};

export default Layout;
