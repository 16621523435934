import { CaregiverCustomer } from 'nhi.shared/dist/models/caregiver-customer';
import { setCookie, getCookie } from 'nhi.shared/dist/utils/cookie';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

import { getCustomMeasurementTypes } from './measurementTypes.actions';

export const GET_CAREGIVER_CUSTOMERS = 'GET_CAREGIVER_CUSTOMERS';
export const SET_ACTIVE_CUSTOMER = 'SET_ACTIVE_CUSTOMER';

const getCaregiverCustomersAction = createAction(GET_CAREGIVER_CUSTOMERS);
const setCaregiverCustomerAction = createAction(SET_ACTIVE_CUSTOMER);

export const getCaregiverCustomers = async (dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)<CaregiverCustomer[]>(
      '/api/customer/GetCustomersForCaregiver',
      {
        takeSessions: 0
      }
    );
    dispatch(getCaregiverCustomersAction(result));

    const customerId = getCookie('customerId');
    let customerData: CaregiverCustomer;

    if (customerId) {
      customerData = result.find((c) => c.customerId === customerId);
    }

    customerData = customerData || result[0];

    dispatch(setCaregiverCustomer(customerData));
  } catch {}
};

export const setCaregiverCustomer = (customer: CaregiverCustomer) => (dispatch) => {
  dispatch(setCaregiverCustomerAction(customer));
  if (customer?.customerId) {
    dispatch(getCustomMeasurementTypes(customer.customerId));
  }
  setCookie('customerId', customer.customerId);
};
