import { AddNewMeasurementsParams } from 'nhi.shared/dist/models/addNewMeasurement';
import { MeasurementGroup } from 'nhi.shared/dist/models/measurements';
import { PatientsSessions } from 'nhi.shared/dist/models/patient-session';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import { ADD_MEASUREMENTS } from '../../actions/patients/measurements.actions';
import { GET_PATIENT_SESSIONS, SIGN_PATIENT_SESSIONS } from '../../actions/patients/patients.actions';

export const initialState: PatientsSessions = {};

const patientsSessionsReducer = createReducer(initialState, {
  [GET_PATIENT_SESSIONS]: (state, { userId, data }) => ({ ...state, [userId]: data }),
  [ADD_MEASUREMENTS]: (state, { userId, date, sessionId, measurements }) => ({
    ...state,
    [userId]: [
      {
        sessionId,
        date,
        measurementGroups: mapNewMeasurementToMeasurementGroups(measurements),
        isSigned: true,
        responses: [],
        isOpen: false,
        questionnaires: [],
        manualValues: true
      },
      ...state[userId]
    ]
  }),
  [SIGN_PATIENT_SESSIONS]: (state, { userId, data }) => {
    const sessions = state[userId]?.map(session =>
      data.includes(session.sessionId) ? { ...session, isSigned: true } : session
    );

    return { ...state, [userId]: sessions };
  }
});

export default patientsSessionsReducer;

const mapNewMeasurementToMeasurementGroups = (
  measurements: AddNewMeasurementsParams['measurements']
): MeasurementGroup[] => {
  const result = measurements.reduce((acc, m) => {
    let groupId = m.measurementTypeId === -1 ? -1 : Math.max(0, m.measurementTypeId - 2);
    const oxygenSaturationItem = [8,9].includes(m.measurementTypeId);
    if(oxygenSaturationItem) {
      groupId = 6;
    }
    const group = acc.find(g => g.group === groupId);

    if (group) {
      group.measurements.push(m);
      return acc.filter(g => (g.group === groupId ? group : g));
    }

    return [
      ...acc,
      {
        group: groupId,
        measurements: [m]
      }
    ];
  }, []);

  return result;
};
