import { AccessLog } from 'nhi.shared/dist/models/access-log';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import { GET_ACCESS_LOGS } from '../actions/access-logs-action';

export const initialState: AccessLog[] = [];

const accessLogsReducer = createReducer(initialState, {
  [GET_ACCESS_LOGS]: (_, payload) => payload
});

export default accessLogsReducer;
