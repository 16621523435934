import { UsersAuthOptions } from 'nhi.shared/dist/models/auth-options';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import { UPDATE_AUTH_OPTIONS } from '../../actions/core/auth-options.action';

export const initialState: UsersAuthOptions = {};

const authOptionsReducer = createReducer(initialState, {
  [UPDATE_AUTH_OPTIONS]: (state, { userId, authOptions }) => ({
    ...state,
    [userId]: authOptions
  })
});

export default authOptionsReducer;
