import { createReducer } from 'nhi.shared/dist/utils/redux';

import {
  SIGNED_IN,
  LOADING,
  SIGN_IN_STATUS_CHANGE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  USE_PATIENT_ACCESS_MODE
} from '@store/actions/core/application.actions';
import { SET_ACTIVE_CUSTOMER } from '@store/actions/customers/caregiver-customers.actions';

import { getUserData } from '../../../auth';
import { Application } from '../../../models/application';

export const initialState: Application = {
  activeCustomer: null,
  isSignedIn: false,
  isLoading: false,
  signInResultCode: undefined,
  signInResultError: undefined,
  patientAccessMode: false,
  snackbar: {
    show: false,
    duration: 5000,
    message: '',
    type: 'success'
  },
  user: getUserData()
};

const applicationReducer = createReducer(initialState, {
  [SET_ACTIVE_CUSTOMER]: (state, activeCustomer) => ({
    ...state,
    activeCustomer
  }),
  [USE_PATIENT_ACCESS_MODE]: (state) => ({
    ...state,
    patientAccessMode: true
  }),
  [SIGNED_IN]: (state, user) => ({
    ...state,
    isSignedIn: true,
    isConnecting: false,
    user: { ...user }
  }),
  [SIGN_IN_STATUS_CHANGE]: (state, { code, error }) => ({
    ...state,
    signInResultCode: code,
    signInResultError: error
  }),
  [LOADING]: (state, isLoading) => ({ ...state, isLoading }),
  [SHOW_SNACKBAR]: (state, snackbar) => ({
    ...state,
    snackbar: {
      ...state.snackbar,
      show: true,
      ...snackbar
    }
  }),
  [HIDE_SNACKBAR]: (state) => ({ ...state, snackbar: initialState.snackbar })
});

export default applicationReducer;
