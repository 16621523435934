import { MdSearch } from '@react-icons/all-files/md/MdSearch';
import { useRouter } from 'next/router';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'nhi.shared/dist/components/atoms/Input';
import Menu, { MenuItem } from 'nhi.shared/dist/components/molecules/Menu';
import { Patient } from 'nhi.shared/dist/models/patient';
import useClickOutside from 'nhi.shared/dist/utils/clickOutside';
import { useDebounce } from 'nhi.shared/dist/utils/debounce';

import { apiFetch } from '@lib/api';

import { selectActiveCustomerId } from '@store/selectors/application.selectors';

import { routes } from '../../config/routes';

interface PatientSearchProps {
  styles?: React.CSSProperties;
}

const PatientSearch: React.FC<PatientSearchProps> = ({ styles }) => {
  const ref = useRef();
  const router = useRouter();
  const { t } = useTranslation();
  const SEARCH_PLACEHOLDER = t('Find patient');
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState(undefined);
  const [showResults, setShowResults] = useState(false);
  const dispatch = useDispatch();
  const activeCustomerId = useSelector(selectActiveCustomerId);

  useEffect(() => {
    setQuery('');
  }, [router.pathname]);

  const handleInputFocus = () => setShowResults(searchResults?.length);

  const hideResults = () => setShowResults(false);

  useClickOutside(ref, hideResults);

  const search = async () => {
    if (query.length < 3) {
      return;
    }

    try {
      const data = await apiFetch(dispatch)('/api/CareRecipient/FindCareRecipients', {
        searchTerm: query,
        directoryId: activeCustomerId
      });
      setSearchResults(data.result);
      setShowResults(true);
    } catch (error) {
      //TODO Add snackbar
    }
  };

  const debouncedSearch = useDebounce(search, 1000);

  const handleInputChange = ({ target }) => {
    setQuery(target.value);

    if (!target.value) {
      return setSearchResults(null);
    }

    debouncedSearch(target.value);
  };

  const handleResultClick = (userId: string) => () => {
    router.push(routes.patientDetails, `${routes.patients}/${userId}`);
    setQuery('');
    setSearchResults(undefined);
    hideResults();
  };

  const renderResults = () =>
    searchResults && (
      <Menu className="results">
        {searchResults.length ? (
          searchResults.map((r: Patient) => (
            <MenuItem key={r.userId} onClick={handleResultClick(r.userId)}>
              {r.firstName + ' ' + r.lastName}
            </MenuItem>
          ))
        ) : (
          <MenuItem>{t('No results')}</MenuItem>
        )}
      </Menu>
    );

  return (
    <>
      <div ref={ref} className="search" style={styles}>
        <MdSearch fontSize="24px" />
        <Input
          type="search"
          className="search-input"
          value={query}
          placeholder={SEARCH_PLACEHOLDER}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        {renderResults()}
      </div>

      <style jsx>{`
        .search {
          display: flex;
          flex: 1;
          max-width: 800px;
          position: relative;
          align-items: center;
          margin-right: 20px;
        }

        .search :global(.search-input:focus-within) {
          font-size: 16px;
          color: black;
        }

        .search :global(.search-input) {
          display: flex;
          flex: 1;
          height: 60px;
          border-bottom: 1px solid #e9e9e9;
        }

        .search :global(.results) {
          position: absolute;
          top: 100%;
          left: 0;
          border-radius: 0;
          width: 100%;
          z-index: 100;
          max-height: 300px;
          overflow-y: auto;
        }

        .search :global(.menu-item) {
          justify-content: flex-start;
        }
      `}</style>

      <style jsx>{`
        .search :global(.results) {
          visibility: ${showResults ? 'visible' : 'hidden'};
        }
      `}</style>
    </>
  );
};

export default PatientSearch;
