import type { IncomingMessage } from 'http';

import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import { parseISO } from 'date-fns';

import { UserData } from 'nhi.shared/dist/models/user-data';
import { UserRole } from 'nhi.shared/dist/models/user-role';
import { arrayIsEmpty } from 'nhi.shared/dist/utils/array';


const COOKIE_NAME = 'user_data';

export const setUserData = (userData: UserData) => {
  if (!userData) {
    removeUserData();
    return;
  }
  setCookie(COOKIE_NAME, encodeURIComponent(JSON.stringify(userData)));
};

export const getUserData = (req?: IncomingMessage, res?: IncomingMessage) => {
  const cookie = getCookie(COOKIE_NAME, { req });
  if (!cookie) {
    return undefined;
  }

  return JSON.parse(decodeURIComponent('' + cookie)) as UserData;
};

export const isTokenValid = (req?: IncomingMessage, res?: IncomingMessage) => {
  const userData = getUserData(req, res);
  if (!userData) {
    return false;
  }
  const result = userData.token && new Date() < parseISO(userData.tokenExpiresOn.toString());
  return result;
};

export const removeUserData = () => {
  deleteCookie(COOKIE_NAME);
};

export const getUserRoles = (req?: IncomingMessage, res?: IncomingMessage) => {
  const userData = getUserData(req, res);
  if (!userData) {
    return [];
  }
  return userData.roles;
};

export const isUserInRoles = (requiredRoles: UserRole[], userRoles: UserRole[]) => {
  if (!userRoles || arrayIsEmpty(userRoles)) {
    return false;
  }

  return requiredRoles.some((role) => userRoles.some((x) => x === role));
};
