import { AvailableHealthPackage } from 'nhi.shared/dist/models/available-health-package';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import { GET_AVAILABLE_HEALTH_PACKAGES } from '../../actions/health-packages.actions';

export const initialState: AvailableHealthPackage[] = null;

const availableHealthPackagesReducer = createReducer(initialState, {
  [GET_AVAILABLE_HEALTH_PACKAGES]: (_, payload) => payload
});

export default availableHealthPackagesReducer;
