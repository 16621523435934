import { AuthOptionTypeKey } from 'nhi.shared/dist/models/auth-options';
import { RegisterPatientForm } from 'nhi.shared/dist/models/register-patient-form';
import { AuthOption, RegisterPatientRequest } from 'nhi.shared/dist/models/register-patient-request';

export const preparePatientRegistrationPayload = (
  form: RegisterPatientForm,
  customerId: string
): RegisterPatientRequest => {
  const {
    personalIdentityNumber,
    bankId,
    rfid,
    pin,
    cardNumber,
    firstName,
    lastName,
    email,
    phoneNo,
    town,
    postalCode,
    street,
    streetNo,
    height
  } = form;
  const swedishId = personalIdentityNumber.replace('-', '');
  const authOptions: AuthOption[] = [];

  if (bankId) {
    authOptions.push({ token: swedishId, authOptionType: AuthOptionTypeKey.mobileBankId });
  }

  if (rfid) {
    authOptions.push({ token: cardNumber, token2: pin, authOptionType: AuthOptionTypeKey.rfid });
  }

  const request: RegisterPatientRequest = {
    registeredByCustomerId: customerId,
    authOptions,
    person: {
      firstName,
      lastName,
      height: !height ? null : +height,
      nationalIdentifier: {
        number: swedishId,
        countryId: 0
      },
      contactDetails: {
        email,
        phoneNo,
        town,
        postalCode,
        street,
        streetNo
      }
    }
  };
  return request;
};
