import { Dispatch } from 'redux';

import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

export const GET_ACCESS_LOGS = 'GET_ACCESS_LOGS';

const getAccessLogsAction = createAction(GET_ACCESS_LOGS);

export const getAccessLogs = (start, end) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)('/api/AccessLog/GetAccessLogs', { start, end });

    dispatch(getAccessLogsAction(result));
  } catch {}
};
