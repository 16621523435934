import { MdMenu } from '@react-icons/all-files/md/MdMenu';

import Flex from 'nhi.shared/dist/components/atoms/Flex';
import { useMediaQuery, Breakpoints } from 'nhi.shared/dist/utils/useMediaQuery';

import PatientSearch from '@components/molecules/PatientSearch';
import UserMenu from '@components/molecules/UserMenu';

import styles from './styles.module.css';

const TopBar = ({ toggleDrawer }) => {
  const isTablet = useMediaQuery(Breakpoints.MD);
  const isMobile = useMediaQuery(Breakpoints.SM);
  const openDrawer = () => toggleDrawer(true);

  return (
    <Flex.Container justify="space-between" align="center" className={styles.container}>
      {isTablet && <MdMenu
        data-testid="mdmenu-toggle-button"
        onClick={openDrawer}
        fontSize="35px"
        className={styles.menuIcon}
      />}
      {!isMobile ? <PatientSearch /> : <div />}
      <UserMenu />
    </Flex.Container>
  );
};

export default TopBar;
