import { Dispatch } from 'redux';

import { CustomerSettings } from 'nhi.shared/dist/models/customer';
import { UpdateCustomerHealthPackages } from 'nhi.shared/dist/models/health-package';
import { Questionnaire, QuestionnaireToAdd } from 'nhi.shared/dist/models/questionnaire';
import { UserRole } from 'nhi.shared/dist/models/user-role';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

import { RootStore } from '@store/reducers/root.reducer';
import { selectCurrentUserData } from '@store/selectors/application.selectors';

import { showSnackbarAction, loadingAction } from './application.actions';

export const GET_ADMINISTRATOR_CUSTOMERS = 'GET_ADMINISTRATOR_CUSTOMERS';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_ADMINISTRATOR_QUESTIONNAIRES = 'GET_ADMINISTRATOR_QUESTIONNAIRES';
export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const CLEAR_QUESTIONNAIRE = 'CLEAR_QUESTIONNAIRE';
export const GET_ADMINISTRATOR_HEALTH_PACKAGES = 'GET_ADMINISTRATOR_HEALTH_PACKAGES';
export const UPDATE_CUSTOMER_SETTINGS = 'UPDATE_CUSTOMER_SETTINGS';
export const UPDATE_CUSTOMER_QUESTIONNAIRES = 'UPDATE_CUSTOMER_QUESTIONNAIRES';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const QUESTIONNAIRE_REMOVED = 'QUESTIONNAIRE_REMOVED';

const getAdministratorCustomersAction = createAction(GET_ADMINISTRATOR_CUSTOMERS);
const getCustomerAction = createAction(GET_CUSTOMER);
const getAdministratorQuestionnairesAction = createAction(GET_ADMINISTRATOR_QUESTIONNAIRES);
const getQuestionnaireAction = createAction(GET_QUESTIONNAIRE);
export const clearQuestionnaireAction = createAction(CLEAR_QUESTIONNAIRE);
const questionnaireRemovedAction = createAction(QUESTIONNAIRE_REMOVED);
const getAdministratorHealthPackagesAction = createAction(GET_ADMINISTRATOR_HEALTH_PACKAGES);

const updateGeneralSettingsAction = createAction(UPDATE_CUSTOMER_SETTINGS);
const updateCustomerQuestionnairesAction = createAction(UPDATE_CUSTOMER_QUESTIONNAIRES);

const addCustomerAction = createAction(ADD_CUSTOMER);

export const getAdministratorCustomers = async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)('/api/customer/GetCustomersForAdministrator');
    dispatch(getAdministratorCustomersAction(result));
  } catch {}
};

export const getCustomer = (customerId: string) => async (dispatch: Dispatch, getStore: () => RootStore) => {
  try {
    const userData = selectCurrentUserData(getStore());
    const isAdmin = userData.roles.find((x) => x == UserRole.Administrator);
    const { result } = await apiFetch(dispatch)(
      isAdmin ? '/api/customer/GetCustomerForAdministrator' : '/api/customer/GetCustomerForCaregiver',
      {
        directoryId: customerId
      }
    );
    dispatch(getCustomerAction(result));
  } catch {}
};

export const getQuestionnaire = (questionnaireId: string) => async (dispatch: Dispatch) => {
  const data = await apiFetch(dispatch)('/api/Questionnaire/GetQuestionnaire', {
    questionnaireId
  });

  if (data?.result) {
    dispatch(getQuestionnaireAction(data.result));
  }
};

export const removeQuestionnaire = (questionnaire: Questionnaire) => async (dispatch: Dispatch) => {
  try {
    dispatch(loadingAction(true));
    await apiFetch(dispatch)('/api/Questionnaire/RemoveQuestionnaire', {
      questionnaireId: questionnaire.questionnaireId
    });
    await dispatch(loadingAction(false));
    await dispatch(questionnaireRemovedAction(questionnaire.questionnaireId));
  } catch (err) {
    dispatch(showSnackbarAction({ message: 'Remove questionnaire failed', type: 'error', duration: 2500 }));
  }
};

export const addQuestionnaire = (questionnaire: QuestionnaireToAdd) => async (dispatch: Dispatch) => {
  try {
    dispatch(loadingAction(true));
    await apiFetch(dispatch)('/api/Questionnaire/AddQuestionnaire', questionnaire);
    dispatch(loadingAction(false));
  } catch (err) {
    dispatch(showSnackbarAction({ message: 'Add questionnaire failed', type: 'error', duration: 2500 }));
  }
};

export const editQuestionnaire = (questionnaire: QuestionnaireToAdd) => async (dispatch: Dispatch) => {
  try {
    dispatch(loadingAction(true));
    await apiFetch(dispatch)('/api/Questionnaire/EditQuestionnaire', questionnaire);
    dispatch(loadingAction(false));
  } catch (err) {
    dispatch(showSnackbarAction({ message: 'Edit questionnaire failed', type: 'error', duration: 2500 }));
  }
};

export const getAdministratorQuestionnaires = async (dispatch: Dispatch) => {
  const data = await apiFetch(dispatch)('/api/questionnaire/getAllQuestionnaires');

  if (data?.result) {
    dispatch(getAdministratorQuestionnairesAction(data.result));
  }
};

export const updateCustomerQuestionnaires = (params) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)('/api/customer/UpdateCustomerQuestionnaires', params);
    dispatch(updateCustomerQuestionnairesAction(result));
  } catch {}
};

export const getAdministratorHealthPackages = async (dispatch: Dispatch) => {
  const data = await apiFetch(dispatch)('/api/healthPackage/getAllHealthPackages');

  if (data?.result) {
    dispatch(getAdministratorHealthPackagesAction(data.result));
  }
};

interface updateGeneralSettings extends CustomerSettings {
  directoryId: string;
}

export const updateGeneralSettings = (params: updateGeneralSettings) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)('/api/customer/updateCustomerSettings', params);
    dispatch(showSnackbarAction({ message: 'Settings saved', type: 'success', duration: 2500 }));
    dispatch(updateGeneralSettingsAction(result as updateGeneralSettings));
  } catch {
    dispatch(showSnackbarAction({ message: 'Saving settings failed', type: 'error', duration: 2500 }));
  }
};

export const updateCustomerHealthPackages =
  (params: UpdateCustomerHealthPackages) => async (dispatch: Dispatch) => {
    try {
      await apiFetch(dispatch)('/api/customer/UpdateCustomerHealthPackages', params);
      dispatch(showSnackbarAction({ message: 'Settings saved', type: 'success', duration: 2500 }));
    } catch {
      dispatch(showSnackbarAction({ message: 'Saving settings failed', type: 'error', duration: 2500 }));
    }
  };

export const addCustomer = (name: string) => (dispatch) => {
  return apiFetch(dispatch)('/api/Customer/AddCustomer', { name }).then(({ result }) => {
    dispatch(addCustomerAction(result));
  });
};
