import { MdKeyboardArrowDown } from '@react-icons/all-files/md/MdKeyboardArrowDown';
import { useEffect, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'nhi.shared/dist/components/atoms/Button';
import Divider from 'nhi.shared/dist/components/atoms/Divider';
import Dropdown from 'nhi.shared/dist/components/atoms/Dropdown';
import Flex from 'nhi.shared/dist/components/atoms/Flex';
import Menu, { MenuItem } from 'nhi.shared/dist/components/molecules/Menu';
import theme from 'nhi.shared/dist/config/theme';
import { CaregiverCustomer } from 'nhi.shared/dist/models/caregiver-customer';

import {
  getCaregiverCustomers,
  setCaregiverCustomer
} from '@store/actions/customers/caregiver-customers.actions';
import { RootStore } from '@store/reducers/root.reducer';

import { Notifications } from '../organisms/Notifications';

import { MessageCenterIcon } from './MessageCenterIcon';

const styles: Record<string, CSSProperties> = {
  button: {
    display: 'inline-flex',
    borderLeft: `2px solid ${theme.gray5}`,
    padding: '5px 0 5px 40px',
    borderRadius: 0
  },
  divider: {
    marginLeft: 20
  }
};

const getPopupContainer = () => document.getElementById('root');

const UserMenu = () => {
  const dispatch = useDispatch();
  const customers = useSelector((store: RootStore) => store.caregiverCustomers);
  const activeCustomer = useSelector(({ application }: RootStore) => application.activeCustomer);

  useEffect(() => {
    dispatch(getCaregiverCustomers);
  }, []);

  const handleCustomerSet = (customer: CaregiverCustomer) => () => {
    dispatch(setCaregiverCustomer(customer));
  };

  const generateMenu = () => (
    <Menu>
      {Object.values(customers).map(customer => (
        <MenuItem key={customer.customerId} onClick={handleCustomerSet(customer)} dropdown>
          {customer.name}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <Flex.Container align="center">
        <MessageCenterIcon />
        <Notifications />
        <Divider variant="vertical" style={styles.divider} />

        <Dropdown overlay={generateMenu()} getPopupContainer={getPopupContainer}>
          <Button
            testid="customer-toggle-button"
            size="large"
            fontColor="primary"
            style={styles.button}
            ghost
          >
            {activeCustomer?.name} <MdKeyboardArrowDown fontSize="24px" />
          </Button>
        </Dropdown>
      </Flex.Container>
    </>
  );
};

export default UserMenu;
