import { PatientsMeasurements } from 'nhi.shared/dist/models/measurements';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import { GET_PENDING_MEASUREMENTS } from '../../actions/patients/measurements.actions';
import { SIGN_PATIENT_SESSIONS } from '../../actions/patients/patients.actions';

export const initialState: PatientsMeasurements = {};

const measurementsReducer = createReducer(initialState, {
  [GET_PENDING_MEASUREMENTS]: (state, payload) => ({ ...state, ...payload }),
  [SIGN_PATIENT_SESSIONS]: (state, { userId, customerId }) => {
    const patients = state[customerId].items;
    const updated = {};
    Object.keys(patients).forEach(key => {
      if (patients[key].userId == userId) {
        return;
      }
      updated[key] = patients[key];
    });
    return { ...state, [customerId]: { ...state[customerId], items: updated } };
  }
});

export default measurementsReducer;
