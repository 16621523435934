import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import ReduxThunk from 'redux-thunk';

import { getRootReducer, getInitialState } from './reducers/root.reducer';

const initStore = () => {
  const composeEnhancers = composeWithDevTools({});

  return createStore(getRootReducer(), getInitialState(), composeEnhancers(applyMiddleware(ReduxThunk)));
};

export default initStore;
