import { MdCheckCircle } from '@react-icons/all-files/md/MdCheckCircle';
import { MdErrorOutline } from '@react-icons/all-files/md/MdErrorOutline';
import { MdHighlightOff } from '@react-icons/all-files/md/MdHighlightOff';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Typography from 'nhi.shared/dist/components/atoms/Typography';
import theme from 'nhi.shared/dist/config/theme';

import { hideSnackbarAction } from '@store/actions/core/application.actions';
import { RootStore } from '@store/reducers/root.reducer';

const icons = {
  success: <MdCheckCircle fontSize="35px" color={theme.secondary} />,
  warning: <MdErrorOutline fontSize="35px" color={theme.warn} />,
  error: <MdHighlightOff fontSize="35px" color={theme.error} />
};

export const Snackbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isVisible, setVisibility] = useState(false);
  const snackbar = useSelector(({ application }: RootStore) => application.snackbar);
  const { type, message, duration, show } = snackbar;

  useEffect(() => {
    if (!show) {
      return;
    }

    setVisibility(true);

    setTimeout(() => {
      setVisibility(false);
    }, duration - 300);

    setTimeout(() => {
      dispatch(hideSnackbarAction());
    }, duration);
  }, [show]);

  return (
    <>
      <div data-testid="snackbar" className={clsx('snackbar', { show: isVisible })}>
        {icons[type]}
        <Typography>{t<any>(message)}</Typography>
      </div>
      <style jsx>{`
        .snackbar {
          display: flex;
          align-items: center;
          position: absolute;
          right: 50px;
          bottom: 50px;
          min-width: 300px;
          min-height: 100px;
          padding: 20px;
          overflow: hidden;
          visibility: hidden;
          z-index: 10;

          background: #f2f2f2;
          box-shadow: 0px 10px 20px ${theme.shadow};
          border-radius: 5px;
          opacity: 0;
          transition: opacity 0.3s;
        }

        .snackbar :global(.text) {
          margin-left: 20px;
        }

        .show {
          opacity: 1;
          visibility: visible;
          animation: slideIn 0.5s forwards;
        }

        @keyframes slideIn {
          0% {
            transform: translateX(120%);
          }

          100% {
            transform: translateX(0);
          }
        }
      `}</style>
    </>
  );
};
