import { MdChatBubbleOutline } from '@react-icons/all-files/md/MdChatBubbleOutline';
import { MdNotificationsNone } from '@react-icons/all-files/md/MdNotificationsNone';
import { MdWarning } from '@react-icons/all-files/md/MdWarning';
import clsx from 'clsx';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Badge from 'nhi.shared/dist/components/atoms/Badge';
import Dropdown from 'nhi.shared/dist/components/atoms/Dropdown';
import Flex from 'nhi.shared/dist/components/atoms/Flex';
import Typography from 'nhi.shared/dist/components/atoms/Typography';
import theme from 'nhi.shared/dist/config/theme';
import {
  Notification,
  NotificationType,
  MeasurementExceededNotification
} from 'nhi.shared/dist/models/notification';
import { getDate } from 'nhi.shared/dist/utils/date';
import { Breakpoints } from 'nhi.shared/dist/utils/useMediaQuery';

import { redirectToPatientMeasurement } from '@utils/notification.utils';

import { getAllNotifications, markNotificationAsRead } from '@store/actions/core/communication.actions';
import { setCaregiverCustomer } from '@store/actions/customers/caregiver-customers.actions';
import { RootStore } from '@store/reducers/root.reducer';
import { selectCurrentUserId } from '@store/selectors/application.selectors';
import { selectCaregiverCustomers } from '@store/selectors/caregiver-customers.selector';

const getPopupContainer = () => document.getElementById('root');

const icons: Record<NotificationType, any> = {
  [NotificationType.MeasurementExceeded]: MdWarning,
  [NotificationType.None]: MdChatBubbleOutline,
  [NotificationType.Text]: MdChatBubbleOutline,
  [NotificationType.IncomingSession]: MdChatBubbleOutline
};

const colors: Record<NotificationType, any> = {
  [NotificationType.MeasurementExceeded]: 'red',
  [NotificationType.None]: theme.secondary,
  [NotificationType.Text]: theme.purple,
  [NotificationType.IncomingSession]: theme.orange
};

export const Notifications = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentUserId);
  const notifications = useSelector((store: RootStore) => store.communication.notifications);

  useEffect(() => {
    userId && dispatch(getAllNotifications(userId));
  }, [userId]);

  const unreadNotifications = notifications?.filter(x => !x.isRead).map(x => x.notificationId) ?? [];

  const handleDropdownOpen = () => dispatch(markNotificationAsRead(unreadNotifications));

  return (
    <>
      <Dropdown
        overlay={<NotificationsList notifications={notifications} />}
        getPopupContainer={getPopupContainer}
        style={{ margin: '0 1px 0 29px' }}
        closeOnSelect={false}
        onOpen={handleDropdownOpen}
      >
        <Badge isVisible={unreadNotifications.length > 0}>
          <MdNotificationsNone color={theme.icons} fontSize="24px" />
        </Badge>
      </Dropdown>
    </>
  );
};

const NotificationsList = ({ notifications }: { notifications: Notification[] }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customers = useSelector(selectCaregiverCustomers);

  const notificationActions = {
    [NotificationType.MeasurementExceeded]: async (n: MeasurementExceededNotification) => {
      await redirectToPatientMeasurement(n);
      prepareSelectedCustomer(n);
    }
  };

  const prepareSelectedCustomer = (notification: MeasurementExceededNotification) =>
    notification?.directoryId && setCustomerFromNotification(notification.directoryId);

  const setCustomerFromNotification = customerId => {
    const customer = customers[customerId];
    if (customer) {
      dispatch(setCaregiverCustomer(customer));
    }
  };

  const handleClickNotification = async (notification: Notification) => {
    const action = notificationActions[notification.notificationType];
    try {
      if (action) {
        await action(notification);
      }
    } catch {}
  };

  return (
    <>
      <div className="wrapper">
        <Flex.Container justify="space-between" align="center">
          <div className="counter">
            <Typography color="gray4">{notifications?.length}</Typography>
          </div>
          <Typography weight="bold" letterSpacing="2">
            {t('Activity')}
          </Typography>
          <div></div>
        </Flex.Container>

        <div className="timeline">
          {notifications?.map(n => (
            <NotificationItem key={n.notificationId} notification={n} handleClick={handleClickNotification} />
          ))}
        </div>
      </div>

      <style jsx>{`
        .wrapper {
          width: 360px;
          margin-top: 30px;
          background-color: ${theme.lightBlue};
          border-radius: 5px;
          padding: 30px;
          box-shadow: 0 5px 10px 3px ${theme.shadow};
          max-height: 400px;
          overflow-y: auto;
        }

        .counter {
          background-color: ${theme.gray5};
          padding: 5px 20px;
          border-radius: 45%;
          margin-left: -20px;
        }

        .timeline {
          padding: 20px 0 0 4px;
        }

        @media ${Breakpoints.SM} {
          .wrapper {
            width: 90vw;
          }
        }
      `}</style>
    </>
  );
};

interface NotificationItemProps {
  notification: Notification;
  handleClick: (notification: Notification) => void;
}

const NotificationItem: FunctionComponent<NotificationItemProps> = ({ notification, handleClick }) => {
  const { notificationType, sendDate, properties, isRead } = notification;
  const text = properties['text'];
  const Icon = icons[notificationType];
  const isCursorPointer =
    notificationType === NotificationType.MeasurementExceeded ||
    notificationType == NotificationType.IncomingSession;

  return (
    <>
      <div
        className={clsx('notification', { read: isRead, pointer: isCursorPointer })}
        onClick={() => handleClick(notification)}
      >
        <div className="icon">
          <Icon color={theme.white} fontSize="20px" />
        </div>
        <div className="card">
          <Typography size="tiny" tag="div" letterSpacing="2" color="secondary">
            {getDate(sendDate)}
          </Typography>
          <Typography weight="bold" letterSpacing="1.5">
            {text}
          </Typography>
        </div>
      </div>

      <style jsx>{`
        .icon {
          background-color: ${colors[notificationType]};
        }
      `}</style>

      <style jsx>{`
        .notification {
          display: block;
          position: relative;
          border-left: 2px solid ${theme.gray5};
          padding-bottom: 20px;
        }

        .read {
          opacity: 0.5;
          pointer-events: none;
        }

        .pointer {
          cursor: pointer;
        }

        .icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          position: absolute;
          top: 10px;
          left: -20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .card {
          margin-left: 10px;
          background-color: ${theme.white};
          padding: 20px 10px 20px 30px;
          border-radius: 5px;
          box-shadow: 0 0 10px 0 ${theme.shadow};
        }
      `}</style>
    </>
  );
};
