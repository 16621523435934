import { Dispatch } from 'redux';

import { AddNewMeasurementsParams } from 'nhi.shared/dist/models/addNewMeasurement';
import {
  CustomerMeasurementLimits,
  CustomerLatestMeasurementsResponse
} from 'nhi.shared/dist/models/measurements';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

import { ITEMS_PER_PAGE } from '@components/organisms/Pagination/Pagination';

import {
  mapCustomerMeasurementsLimits,
  mapUsersLatestMeasurements
} from '../../mappers/measurements.mappers';
import { RootStore } from '../../reducers/root.reducer';
import { selectCurrentUserId } from '../../selectors/application.selectors';

export const GET_PENDING_MEASUREMENTS = 'GET_PENDING_MEASUREMENTS';
export const ADD_MEASUREMENTS = 'ADD_MEASUREMENTS';
export const GET_MEASUREMENTS_LIMITS = 'GET_MEASUREMENTS_LIMITS';
export const UPDATE_MEASUREMENTS_LIMITS = 'UPDATE_MEASUREMENTS_LIMITS';
export const REMOVE_MEASUREMENT_LIMIT = 'REMOVE_MEASUREMENT_LIMIT';

const getMeasurementsAction = createAction(GET_PENDING_MEASUREMENTS);
const addMeasurementsAction = createAction(ADD_MEASUREMENTS);
const getMeasurementsLimitsAction = createAction(GET_MEASUREMENTS_LIMITS);
const updateMeasurementsLimitsAction = createAction(UPDATE_MEASUREMENTS_LIMITS);
const removeMeasurementLimitAction = createAction(REMOVE_MEASUREMENT_LIMIT);

export const getMeasurements =
  (customerId: string, measurementsPage: number) => async (dispatch: Dispatch) => {
    try {
      const { result } = await apiFetch(dispatch)<CustomerLatestMeasurementsResponse>(
        '/api/measurement/GetCustomerLatestMeasurements',
        {
          customerId,
          takeSessions: 5,
          skip: measurementsPage * ITEMS_PER_PAGE,
          take: ITEMS_PER_PAGE
        }
      );
      const items = mapUsersLatestMeasurements(result.items);
      dispatch(getMeasurementsAction({ [customerId]: { ...result, items } }));
    } catch {}
  };

export const addNewMeasurements = (params: AddNewMeasurementsParams) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)<{ sessionId: string; userId: string }>(
      '/api/session/AddOrUpdateSessionWithManualValues',
      params
    );
    dispatch(addMeasurementsAction({ ...result, ...params }));
    return result;
  } catch (error) {}
  return;
};

export const getMeasurementsLimits = (directoryIds: string[]) => async (dispatch: Dispatch) => {
  try {
    const data = await apiFetch(dispatch)<CustomerMeasurementLimits[]>(
      '/api/measurementLimit/GetCustomersMeasurementLimits',
      { directoryIds }
    );
    dispatch(getMeasurementsLimitsAction(mapCustomerMeasurementsLimits(data.result)));
  } catch {}
};

export const updateMeasurementsLimits = (params) => async (dispatch: Dispatch, getStore: () => RootStore) => {
  const userId = selectCurrentUserId(getStore());
  try {
    const data = await apiFetch(dispatch)('/api/MeasurementLimit/UpdateMeasurementLimits', {
      userId,
      ...params
    });
    dispatch(updateMeasurementsLimitsAction(data.result));
  } catch (error) {}
};

export const removeMeasurementLimit =
  ({ measurementLimitId, directoryId }) =>
    async (dispatch: Dispatch, getStore: () => RootStore) => {
      const userId = selectCurrentUserId(getStore());

      try {
        await apiFetch(dispatch)('/api/MeasurementLimit/RemoveCareRecipientMeasurementLimit', {
          userId,
          measurementLimitId
        });

        dispatch(removeMeasurementLimitAction({ measurementLimitId, directoryId }));
      } catch (error) {}
    };
