import { routes } from '@config/routes';
import Router from 'next/router';
import { Dispatch } from 'redux';

import { InitializeSithsLoginSessionResponse } from 'nhi.shared/dist/models/initialize-siths-login-session-response';
import { SignInResultCode } from 'nhi.shared/dist/models/sign-in-result-code';
import { UserData } from 'nhi.shared/dist/models/user-data';
import { createAction } from 'nhi.shared/dist/utils/redux';
import { personalIdentityNumber } from 'nhi.shared/dist/utils/validation';

import { apiFetch } from '@lib/api';

import { setUserData, removeUserData, getUserData } from '../../../auth';

export const SIGNED_IN = 'SIGNED_IN';
export const SIGN_IN_STATUS_CHANGE = 'SIGN_IN_STATUS_CHANGE';
export const LOADING = 'LOADING';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const USE_PATIENT_ACCESS_MODE = 'USE_PATIENT_ACCESS_MODE';

const signedInAction = createAction(SIGNED_IN);
export const signInStatusChangeAction = createAction(SIGN_IN_STATUS_CHANGE);
export const loadingAction = createAction(LOADING);
export const showSnackbarAction = createAction(SHOW_SNACKBAR);
export const hideSnackbarAction = createAction(HIDE_SNACKBAR);
export const usePatientAccessMode = createAction(USE_PATIENT_ACCESS_MODE);

const getQueryStringValue = (key: string, search?: string) => {
  return decodeURIComponent(
    (search || window.location.search).replace(
      new RegExp(
        '^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$',
        'i'
      ),
      '$1'
    )
  );
};

const signInCallback = () => {
  const redirectUrl = getQueryStringValue('redirectUrl') ?? getQueryStringValue('redirecturl');
  window.history.replaceState(null, null, location.href.slice(0, location.href.indexOf('?')));
  Router.push(redirectUrl ? decodeURIComponent(redirectUrl) : routes.measurements);
};

export const signInBankId = (login: string) => async (dispatch: Dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };

  const valid = personalIdentityNumber(login);

  if (!valid) {
    dispatch(
      signInStatusChangeAction({
        code: SignInResultCode.Error,
        error: 'Field should have format YYYYMMDDXXXX'
      })
    );
    return;
  }

  let swedishId = login;
  if (/^\d{8}-\d{4}$/.test(login)) {
    swedishId = login.substring(0, 8) + login.substring(9, 13);
  }

  const data = { personNumber: swedishId };

  try {
    const response = await apiFetch(dispatch)('/api/authenticate/LoginMobileBankId', data, config);
    const result = response.result as UserData;
    setUserData(result);
    dispatch(signedInAction(result));
    signInCallback();
  } catch {
    dispatch(signInStatusChangeAction({ code: SignInResultCode.Error }));
  } finally {
  }
};

export const initializeSithsLoginSession = () => async (dispatch: Dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };
  dispatch(loadingAction(true));
  const response = await apiFetch(dispatch)(
    '/api/authenticate/InitializeSithsLoginSession',
    {
      callbackUrl: window.location.href
    },
    config
  );
  const result = response.result as InitializeSithsLoginSessionResponse;
  dispatch(loadingAction(false));

  if (result.redirectUrl) {
    window.location.replace(result.redirectUrl);
  }
};

export const signInSiths = (sessionId: string) => async (dispatch: Dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };
  try {
    dispatch(loadingAction(true));
    const response = await apiFetch(dispatch)(
      '/api/authenticate/LoginSiths',
      {
        sessionId
      },
      config
    );
    const result = response.result as UserData;
    setUserData(result);
    dispatch(signedInAction(result));
    signInCallback();
  } catch {
    dispatch(signInStatusChangeAction({ code: SignInResultCode.Error }));
  } finally {
    dispatch(loadingAction(false));
  }
};

export const signIn = (login: string, password: string) => async (dispatch: Dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(unescape(encodeURIComponent(login + ':' + password)))}`,
      'Cache-Control': 'no-cache'
    }
  };

  try {
    dispatch(loadingAction(true));
    const response = await apiFetch(dispatch)<UserData>('/api/authenticate/login', null, config);
    const result = response.result;
    setUserData(result);
    dispatch(signedInAction(result));

    dispatch(signInStatusChangeAction({ code: SignInResultCode.Success }));
    signInCallback();
  } catch {
    dispatch(signInStatusChangeAction({ code: SignInResultCode.Error }));
  } finally {
    dispatch(loadingAction(false));
  }
};

export const signOut = async () => {
  const token = getUserData()?.token;

  if (typeof window === 'undefined') {
    removeUserData();
    return;
  }

  if (!token) {
    Router.push(routes.login);
  }

  try {
    await apiFetch(undefined)('/api/authenticate/logout');
  } catch (error) {
  } finally {
    removeUserData();
    Router.push(routes.login);
  }
};

export const refreshLogin = async (dispatch: Dispatch) => {
  try {
    const data = await apiFetch(dispatch)('/api/Authenticate/Refresh', { app: 'caregiver' });
    //TODO Handle this in AuthProvider
    if (window.location.pathname.includes(routes.login)) {
      window.location.pathname = '/';
    }

    const result = data.result as UserData;
    setUserData(result);
    dispatch(signedInAction(result));
  } catch {}
};
