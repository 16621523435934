import { combineReducers } from 'redux';

import { AccessLog } from 'nhi.shared/dist/models/access-log';
import { UsersAuthOptions } from 'nhi.shared/dist/models/auth-options';
import { AvailableHealthPackage } from 'nhi.shared/dist/models/available-health-package';
import { CaregiverCustomers } from 'nhi.shared/dist/models/caregiver-customer';
import { Conversation } from 'nhi.shared/dist/models/conversation';
import { PatientsMeasurements } from 'nhi.shared/dist/models/measurements';
import { OrdinationsByCareRecipientId } from 'nhi.shared/dist/models/ordinations';
import { Patients } from 'nhi.shared/dist/models/patient';
import { PatientsSessions, PhotoReport } from 'nhi.shared/dist/models/patient-session';
import { PatientsDetails } from 'nhi.shared/dist/models/patients-details';

import { Application } from '../../models/application';

import accessLogsReducer, { initialState as accessLogsState } from './access-logs-reducer';
import applicationReducer, { initialState as applicationState } from './core/application.reducer';
import authOptionsReducer, { initialState as authOptionsState } from './core/auth-options.reducer';
import communicationReducer, {
  CommunicationState,
  initialState as communicationState
} from './core/communication.reducer';
import conversationsReducer, { initialState as conversationsState } from './core/conversations.reducer';
import settingsReducer, { Settings, initialState as settingsState } from './core/settings.reducer';
import caregiverCustomersReducer, {
  initialState as caregiverCustomersState
} from './customers/caregiver-customers.reducer';
import availableHealthPackagesReducer, {
  initialState as availableHealthPackagesState
} from './customers/health-packages.reducer';
import measurementsLimitsReducer, {
  MeasurementsLimitsState,
  initialState as measurementsLimitsState
} from './customers/measurementsLimits.reducer';
import measurementTypesReducer, { MeasurementTypesState, initialState as measurementTypesState } from './customers/measurementTypes.reducer';
import homeMonitoringReducer, {
  initialState as homeMonitoringState,
  HomeMonitoringState
} from './home-monitoring.reducer';
import measurementsReducer, { initialState as measurementsState } from './patients/measurements.reducer';
import ordinationsReducer, { initialState as ordinationsState } from './patients/ordinations.reducer';
import patientPhotoReportsReducer, {
  initialState as patientPhotoReportsState
} from './patients/patient-photo-reports';
import patientsDetailsReducer, {
  initialState as patientsDetailsState
} from './patients/patients-details.reducer';
import patientsSessionsReducer, {
  initialState as patientsSessionsState
} from './patients/patients-sessions.reducer';
import patientsReducer, { initialState as patientsState } from './patients/patients.reducer';

export interface RootStore {
  application: Application;
  authOptions: UsersAuthOptions;
  caregiverCustomers: CaregiverCustomers;
  measurements: PatientsMeasurements;
  measurementsLimits: MeasurementsLimitsState;
  measurementTypes: MeasurementTypesState;
  patients: Patients;
  patientsDetails: PatientsDetails;
  patientsSessions: PatientsSessions;
  patientPhotoReports: PhotoReport;
  ordinations: OrdinationsByCareRecipientId;
  settings: Settings;
  communication: CommunicationState;
  availableHealthPackages: AvailableHealthPackage[];
  homeMonitoring: HomeMonitoringState;
  conversations: Conversation[];
  accessLogs: AccessLog[];
}

export const getRootReducer = () =>
  combineReducers({
    application: applicationReducer,
    authOptions: authOptionsReducer,
    caregiverCustomers: caregiverCustomersReducer,
    measurements: measurementsReducer,
    measurementsLimits: measurementsLimitsReducer,
    measurementTypes: measurementTypesReducer,
    patients: patientsReducer,
    patientsDetails: patientsDetailsReducer,
    patientsSessions: patientsSessionsReducer,
    patientPhotoReports: patientPhotoReportsReducer,
    ordinations: ordinationsReducer,
    settings: settingsReducer,
    communication: communicationReducer,
    availableHealthPackages: availableHealthPackagesReducer,
    homeMonitoring: homeMonitoringReducer,
    conversations: conversationsReducer,
    accessLogs: accessLogsReducer
  });

export const getInitialState = (): RootStore => ({
  application: applicationState,
  authOptions: authOptionsState,
  caregiverCustomers: caregiverCustomersState,
  measurements: measurementsState,
  measurementsLimits: measurementsLimitsState,
  measurementTypes: measurementTypesState,
  patients: patientsState,
  patientsDetails: patientsDetailsState,
  patientsSessions: patientsSessionsState,
  patientPhotoReports: patientPhotoReportsState,
  ordinations: ordinationsState,
  settings: settingsState,
  communication: communicationState,
  availableHealthPackages: availableHealthPackagesState,
  homeMonitoring: homeMonitoringState,
  conversations: conversationsState,
  accessLogs: accessLogsState
});
