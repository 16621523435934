import Head from 'next/head';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import theme from 'nhi.shared/dist/config/theme';
import { getCookie } from 'nhi.shared/dist/utils/cookie';

import initStore from '@store/index';

import { Snackbar } from '@components/molecules/Snackbar';
import { WithSockets } from '@components/WithSockets';

import localize from '../i18n';
import Layout from '../templates/Layout';
import 'react-multi-carousel/lib/styles.css';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    font-weight: 600;
    background-color: ${theme.gray1};
    overflow-x: hidden;
  }
  body,
  button,
  input,
  textarea {
    font-family: 'Open Sans', sans-serif;
  }
  @media print {
    body {
      background-color: ${theme.gray1};
    }
  }
  body::backdrop {
    background-color: ${theme.gray1};
  }
  a {
    text-decoration: none;
  }
  ul {
    padding-left: 0;
  }
  li {
    list-style-type: none;
  }
`;

export const store = initStore();

function App({ Component, pageProps }) {
  useEffect(() => {
    localize.changeLanguage(getCookie('lang'));
  }, []);

  return (
    <>
      <GlobalStyle />
      <Provider store={store}>
        <Head>
          <title>Healfy - {localize.t('Caregiver')}</title>
          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={theme}>
          <Layout>
            <WithSockets>
              <Component {...pageProps} />
            </WithSockets>
          </Layout>
          <Snackbar />
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
