import { OrdinationsByCareRecipientId } from 'nhi.shared/dist/models/ordinations';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import {
  GET_ORDINATIONS,
  ADD_ORDINATION,
  REMOVE_ORDINATION
} from '../../actions/patients/ordinations.actions';

export const initialState: OrdinationsByCareRecipientId = {};

const ordinationsReducer = createReducer(initialState, {
  [GET_ORDINATIONS]: (state, { careRecipientId, ordinations }) => ({
    ...state,
    [careRecipientId]: ordinations
  }),
  [ADD_ORDINATION]: (state, { careRecipientId, ordination }) => {
    const ordinations = state[careRecipientId] || [];

    return {
      ...state,
      [careRecipientId]: [ordination, ...ordinations]
    };
  },
  [REMOVE_ORDINATION]: (state, { ordinationId, careRecipientId }) => {
    const ordinations = state[careRecipientId] || [];

    return {
      ...state,
      [careRecipientId]: [...ordinations.filter(x => x.id !== ordinationId)]
    };
  }
});

export default ordinationsReducer;
