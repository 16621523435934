import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import getConfig from 'next/config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSocketStream } from '@store/actions/core/communication.actions';
import { selectCurrentUserData } from '@store/selectors/application.selectors';

import { getUserData } from '../auth';

export enum ConnectionType {
  'webRtcHub',
  'notifications'
}

export const useSocketStream = (type: ConnectionType): [HubConnection, HubConnectionState] => {
  const [connection, setConnection] = useState<HubConnection | undefined>(undefined);
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUserData);
  const { publicRuntimeConfig } = getConfig();

  useEffect(() => {
    if (typeof window !== 'undefined' && !connection) {
      const conn = new HubConnectionBuilder()
        .withUrl(publicRuntimeConfig.API_URL + `/${ConnectionType[type]}`, {
          accessTokenFactory: () => getUserData()?.token ?? ''
        })
        .build();
      setConnection(conn);
      dispatch(setSocketStream({ key: type, connection: conn }));
    }
  }, []);

  useEffect(() => {
    if (!!userData && !!connection && connection.state !== HubConnectionState.Connected) {
      connection.start();
    }
    if (!userData && connection?.state === HubConnectionState.Connected) {
      connection.stop();
    }
  }, [userData, connection]);

  return [connection, HubConnectionState.Connected];
};
