import { NextPageContext } from 'next';
import getConfig from 'next/config';

import { camelize } from 'nhi.shared/dist/utils';
import { getCookie, extractCookieFromCookieString } from 'nhi.shared/dist/utils/cookie';

import { signOut } from '@store/actions/core/application.actions';

import { getUserData } from '../auth';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export const getApiConfig = (
  config: RequestInit = {},
  nextContext?: NextPageContext
): { initConfig: RequestInit; baseUri: string } => {
  const baseUri = publicRuntimeConfig.API_URL || serverRuntimeConfig.API_URL;
  let token = getUserData(nextContext?.req)?.token;
  let directoryId = getCookie('customerId');

  if (!!nextContext && typeof window === 'undefined') {
    // we are on the server side
    const cookieString = nextContext.req.headers.cookie || '';
    token = getUserData(nextContext.req)?.token;
    directoryId = extractCookieFromCookieString('customerId', cookieString);
  }

  const defaultHeaders: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const variableHeaders: RequestInit['headers'] = {
    ...(!!token ? { Token: token } : {}),
    ...(config?.headers ?? {})
  };

  if (directoryId) {
    variableHeaders['DirectoryId'] = directoryId;
  }
  return {
    initConfig: {
      ...config,
      headers: {
        ...defaultHeaders,
        ...variableHeaders
      }
    } as RequestInit,
    baseUri
  };
};

export const apiFetch =
  (dispatch?: any) =>
    async <T>(
      url: string,
      data?: any,
      config?: RequestInit,
      nextContext?: NextPageContext,
      method = 'POST'
    ): Promise<{ result: T }> => {
      let isLoginPage = false;
      if (typeof window === 'undefined') {
        isLoginPage = nextContext.req.url?.toLowerCase().includes('/login');
      } else {
        isLoginPage = window.location.pathname.toLowerCase().includes('/login');
      }
      const { initConfig, baseUri } = getApiConfig(config, nextContext);
      const response = await fetch(baseUri + url, {
        ...initConfig,
        method,
        body: data ? JSON.stringify(data) : undefined
      });

      if (response.status == 401 && !isLoginPage) {
        dispatch && dispatch(signOut as any); // we can be on the server side
        return;
      }

      if (!response.ok && response.status !== 204) {
        const res = await response.json();
        throw res?.Exception?.StatusMessage ?? "Unhandled error occured. We're on it, please try again later.";
      }

      if (response.status == 204) {
        return;
      }

      const json = camelize(await response.json()) as { result: T };
      return json;
    };
