import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import getConfig from 'next/config';
import { withRouter, NextRouter } from 'next/router';
import { Component } from 'react';
import { connect } from 'react-redux';

import VideoChatModal from 'nhi.shared/dist/components/molecules/VideoChatModal';
import { PeerItem, MyPeer, PeerItems, Call } from 'nhi.shared/dist/models/communication';

import { toggleVideoChatAction, setActivePeersAction } from '@store/actions/core/communication.actions';
import { RootStore } from '@store/reducers/root.reducer';
import { selectWebRtcConnection } from '@store/selectors/application.selectors';

export interface CommunicatorProps {
  router: NextRouter;
  isModalOpen: boolean;
  toggleModal: () => void;
  setActivePeers: (peers: PeerItems) => void;
  hubConnection: HubConnection;
}

export interface CommunicatorState {
  isEnabled: boolean;
  peers: PeerItems;
  myPeer?: MyPeer;
  callToken?: string;
  calledPeer?: PeerItem;
  callingPeer?: PeerItem;
  inCall: boolean;
  browser: any;
  callMediaStream: MediaStream;
}

const { publicRuntimeConfig } = getConfig();
const webRTC = () => import('webrtc-adapter') as any;

// TODO Don't use deprecated methods from RTCPConnection API
// TODO ^ -> just rewrite
class Communicator extends Component<CommunicatorProps, CommunicatorState> {
  private myMediaStream: MediaStream;
  private rtcConnection: any;
  private candidates: any[] = [];

  state: CommunicatorState = {
    peers: {} as PeerItems,
    myPeer: null,
    inCall: false,
    callToken: null,
    calledPeer: null,
    callingPeer: null,
    isEnabled: false,
    browser: null,
    callMediaStream: null
  };

  async componentDidMount() {
    const { hubConnection } = this.props;
    const adapter = await webRTC();
    this.setState({ browser: adapter.default.browserDetails });

    hubConnection.onclose(async () => {
      this.stopMediaStreams();

      if (this.rtcConnection) {
        this.candidates = [];
      }

      this.setState({
        peers: {},
        inCall: false,
        isEnabled: false,
        myPeer: undefined,
        callToken: undefined,
        calledPeer: undefined,
        callingPeer: undefined
      });
    });

    hubConnection.on('hello', (hello: any) => {
      hello = JSON.parse(hello);
      const peers = hello.peerList.reduce(
        (acc: PeerItems, cur: PeerItem) => ({ ...acc, [cur.connectionId]: cur }),
        {}
      );

      this.props.setActivePeers(peers);

      this.setState({
        peers,
        myPeer: hello.myPeer,
        isEnabled: peers.length && hubConnection.state === HubConnectionState.Connected
      });
    });

    hubConnection.on('connected', (peer: any) => {
      peer = JSON.parse(peer);
      const peers = this.state.peers || {};
      const newPeers = { ...peers, [peer.connectionId]: peer };

      this.props.setActivePeers(newPeers);
      this.setState({
        peers: { ...peers, [peer.connectionId]: peer },
        isEnabled: isEmpty(peers) && hubConnection.state === HubConnectionState.Connected
      });
    });

    hubConnection.on('disconnected', (peer: any) => {
      peer = JSON.parse(peer);
      const peers = this.state.peers || {};
      delete peers[peer.connectionId];
      this.setState({
        peers: { ...peers },
        isEnabled: isEmpty(peers) && hubConnection.state === HubConnectionState.Connected
      });
    });

    hubConnection.on('statusChanged', (status: any) => {
      status = JSON.parse(status);
      let peer = this.state.peers[status.connectionId];
      if (peer) {
        peer = { ...peer, status: status.status };
        this.setState({
          peers: { ...this.state.peers, [peer.connectionId]: peer }
        });
      }
    });

    hubConnection.on('hangUp', (token: string) => {
      const { myPeer, callToken } = this.state;

      if (myPeer && callToken && callToken === token) {
        this.props.toggleModal();
        this.setState({
          inCall: false,
          callToken: undefined,
          callingPeer: undefined,
          calledPeer: undefined
        });

        this.stopMediaStreams();

        if (this.rtcConnection) {
          this.candidates = [];
        }

        hubConnection.send(
          'statusChanged',
          JSON.stringify({
            status: 'Available',
            connectionId: myPeer.connectionId
          })
        );
      }
    });

    hubConnection.on('answear', async (token: string) => {
      const { calledPeer, callToken } = this.state;

      if (callToken && callToken === token && calledPeer) {
        this.rtcConnection = new RTCPeerConnection({ iceServers: getIceServers() });

        this.rtcConnection.onsignalingstatechange = (event: any) => {
          if (
            this.rtcConnection &&
            this.rtcConnection.signalingState !== 'have-local-offer' &&
            this.candidates
          ) {
            for (const candidate of this.candidates) {
              this.rtcConnection.addIceCandidate(new RTCIceCandidate(candidate));
            }
            this.candidates = [];
          }
        };
        this.rtcConnection.onicecandidate = ({ candidate }: any) => {
          const { callingPeer } = this.state;

          if (candidate && (calledPeer || callingPeer) && token) {
            const peer: any = calledPeer ? calledPeer : callingPeer;
            hubConnection.send('newMessage', peer.connectionId, token, JSON.stringify({ candidate }));
          }
        };

        this.rtcConnection.ontrack = ({ streams }: any) => this.setState({ callMediaStream: streams[0] });

        let stream: MediaStream | null = null;
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {
              facingMode: {
                ideal: ['user']
              },
              width: { ideal: 640, min: 320 },
              height: { ideal: 480, min: 240 }
            }
          });
        } catch {}

        if (stream) {
          this.myMediaStream = stream;
          this.rtcConnection && this.rtcConnection.addStream(stream);
        }

        if (this.rtcConnection) {
          this.rtcConnection.createOffer(
            (desc: any) => {
              // Set the generated SDP to be our local session description
              this.rtcConnection.setLocalDescription(
                desc,
                () => {
                  // And send it to our peer, where it will become their RemoteDescription
                  hubConnection.send(
                    'newMessage',
                    calledPeer.connectionId,
                    token,
                    JSON.stringify({ sdp: desc })
                  );
                },
                () => {}
              );
            },
            () => {},
            {
              offerToReceiveAudio: true,
              offerToReceiveVideo: true
            }
          );
        }

        this.setState({ inCall: true });
      }
    });

    hubConnection.on('newMessage', (callToken: string, data: any) => {
      const storedCallToken = this.state.callToken;

      if (!storedCallToken || storedCallToken !== callToken) {
        return;
      }

      const { calledPeer, callingPeer } = this.state;

      if (!calledPeer && !callingPeer) {
        return;
      }

      const peer: any = calledPeer ? calledPeer : callingPeer;

      const message = JSON.parse(data);

      // An SDP message contains connection and media information, and is either an 'offer' or an 'answer'
      if (message.sdp) {
        this.rtcConnection.setRemoteDescription(
          new RTCSessionDescription(message.sdp),
          () => {
            if (this.rtcConnection.remoteDescription.type === 'offer') {
              // Add our stream to the connection to be shared
              this.rtcConnection.addStream(this.myMediaStream);

              // Create an SDP response
              this.rtcConnection.createAnswer(
                (desc: any) => {
                  // Which becomes our local session description
                  this.rtcConnection.setLocalDescription(
                    desc,
                    () => {
                      // And send it to the originator, where it will become their RemoteDescription
                      hubConnection.send(
                        'newMessage',
                        peer.connectionId,
                        callToken,
                        JSON.stringify({
                          sdp: this.rtcConnection.localDescription
                        })
                      );
                    },
                    () => {}
                  );
                },
                () => {}
              );
            } else if (this.rtcConnection.remoteDescription.type === 'answer') {
            }
          },
          (err: any) => {}
        );
      } else if (message.candidate) {
        if (this.rtcConnection && this.rtcConnection.signalingState !== 'have-local-offer') {
          this.rtcConnection.addIceCandidate(new RTCIceCandidate(message.candidate));
        } else if (this.rtcConnection.signalingState === 'have-local-offer') {
          this.candidates.push(message.candidate);
        }
      }
    });

    if (hubConnection.state === HubConnectionState.Connected) {
      await hubConnection.send('hello');
    }
  }

  componentWillUnmount() {
    this.stopMediaStreams();
  }

  componentDidUpdate(prevProps: CommunicatorProps) {
    const { isModalOpen } = this.props;

    if (isModalOpen && isModalOpen !== prevProps.isModalOpen) {
      this.callPatient();
    }
  }

  private callPatient = () => {
    const { peers, inCall } = this.state;

    const calledPeer = Object.values(peers).find((p) => p.userId === this.props.router.query.patientId);

    if (!inCall && calledPeer) {
      this.call(calledPeer);
    } else {
      this.props.toggleModal();
    }
  };

  private call = (calledPeer: PeerItem) => {
    const { myPeer } = this.state;
    const { hubConnection } = this.props;

    if (!myPeer || this.state.callToken || calledPeer.status === 'InCall') {
      return;
    }

    const callToken = calledPeer.connectionId;
    this.setState({ callToken, calledPeer });

    const call: Call = {
      caller: {
        firstName: myPeer.firstName,
        lastName: myPeer.lastName,
        connectionId: myPeer.connectionId,
        userId: myPeer.userId,
        status: 'Available',
        nationalIdentifier: myPeer.nationalIdentifier
      },
      receiverConnectionId: calledPeer.connectionId
    };

    hubConnection.send('call', calledPeer.connectionId, callToken, JSON.stringify(call));
    hubConnection.send(
      'statusChanged',
      JSON.stringify({
        status: 'InCall',
        connectionId: myPeer.connectionId
      })
    );
  };

  private handleCancelCallClick = () => {
    const { callToken, calledPeer, callingPeer, myPeer } = this.state;
    const { hubConnection } = this.props;
    if (myPeer && (calledPeer || callingPeer) && callToken) {
      const peer: any = calledPeer ? calledPeer : callingPeer;
      hubConnection.send('hangUp', peer.connectionId, callToken);

      this.setState({
        callToken: undefined,
        calledPeer: undefined,
        callingPeer: undefined,
        inCall: false
      });

      this.props.toggleModal();

      this.stopMediaStreams();

      hubConnection.send(
        'statusChanged',
        JSON.stringify({
          status: 'Available',
          connectionId: myPeer.connectionId
        })
      );
    }
  };

  private stopMediaStreams = () => {
    const { callMediaStream } = this.state;
    if (this.myMediaStream) {
      this.myMediaStream.getTracks().forEach((track) => track.stop());
      this.myMediaStream = undefined;
    }
    if (callMediaStream) {
      callMediaStream.getTracks().forEach((track) => track.stop());
      this.setState({ callMediaStream: null });
    }
    if (this.rtcConnection) {
      this.rtcConnection.close();
      this.rtcConnection = undefined;
    }
  };

  render() {
    const { inCall, calledPeer, callMediaStream } = this.state;
    const { isModalOpen } = this.props;

    return (
      <VideoChatModal
        isInCall={inCall}
        isOpen={isModalOpen}
        myMediaStream={this.myMediaStream}
        callMediaStream={callMediaStream}
        peer={calledPeer}
        handleCancelCallClick={this.handleCancelCallClick}
      />
    );
  }
}

const mapStoreToProps = (store: RootStore) => ({
  isModalOpen: store.communication.videoChat,
  hubConnection: selectWebRtcConnection(store)
});

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleVideoChatAction()),
  setActivePeers: (peers: PeerItems) => dispatch(setActivePeersAction(peers))
});

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Communicator));

const isEmpty = (object: object = {}) => !Object.keys(object).length;

export const getIceServers = () => {
  return publicRuntimeConfig.ICE_SERVERS ? JSON.parse(publicRuntimeConfig.ICE_SERVERS) : [];
};
