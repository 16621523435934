import { createReducer } from 'nhi.shared/dist/utils/redux';

import { GET_MEASUREMENTS_LIMITS } from '../../actions/patients/measurements.actions';

export interface MeasurementLimits {
  min: number;
  max: number;
}
export type MeasurementsLimits = Record<number, MeasurementLimits>;
export type MeasurementsLimitsState = Record<string, MeasurementsLimits>;

export const initialState: MeasurementsLimitsState = {};

const measurementsLimitsReducer = createReducer(initialState, {
  [GET_MEASUREMENTS_LIMITS]: (state, payload) => ({ ...state, ...payload })
});

export default measurementsLimitsReducer;
