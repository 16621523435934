import { Dispatch } from 'redux';

import { Conversation } from 'nhi.shared/dist/models/conversation';
import { sortMessages } from 'nhi.shared/dist/utils/messages';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

export const GET_CONVERSATIONS_HISTORY = 'GET_CONVERSATIONS_HISTORY';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const READ_CONVERSATION = 'READ_CONVERSATION';

const getConversationsHistoryAction = createAction(GET_CONVERSATIONS_HISTORY);
export const messageReceivedAction = createAction(MESSAGE_RECEIVED);
const readConversationAction = createAction(READ_CONVERSATION);

export const getConversationsHistory = () => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)<Conversation[]>(
      '/api/Conversations/GetConversationsHistory',
      {}
    );

    dispatch(getConversationsHistoryAction(sortMessages(result)));
  } catch {}
};

export const sendMessage =
  (careRecipientId: string, message: string, isPrioritized: boolean, conversationId?: string) =>
    async (dispatch) => {
      try {
        await apiFetch(dispatch)(
          '/api/Conversations/SendMessage',
          {
            careRecipientId,
            text: message,
            isPrioritized,
            conversationId
          },
          undefined,
          undefined,
          'PUT'
        );
      } catch {}
    };

export const readConversation =
  (conversationId: string, currentUserId: string) => async (dispatch: Dispatch) => {
    dispatch(readConversationAction({ conversationId, currentUserId }));

    try {
      await apiFetch(dispatch)(
        '/api/Conversations/ReadConversation',
        {
          conversationId
        },
        undefined,
        undefined,
        'PUT'
      );
    } catch {}
  };
