import getConfig from 'next/config';
import * as React from 'react';

import Modal from 'nhi.shared/dist/components/atoms/Modal';
import Typography from 'nhi.shared/dist/components/atoms/Typography';
import { Breakpoints } from 'nhi.shared/dist/utils/useMediaQuery';

interface HealfyInformationModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const HealfyInformationModal: React.FC<HealfyInformationModalProps> = ({ isOpen, onClose }) => {
  const { publicRuntimeConfig } = getConfig();

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <aside className="modal-wrapper">
          <img src="/images/logo-dark.png" className="logo" />

          <section className="logos-grid">
            <div className="grid-item">
              <img src="/images/ce/CEsymbol.jpg" />
            </div>
            <div className="grid-item">
              <img src="/images/ce/Manufacturer-Fabriksymbol.jpg" />
            </div>
            <Typography style={{ width: '100%', display: 'block', padding: '20px 10px' }}>
              Nordic health Innovation
              <br />
              Drottninggatan 94, Stockholm
              <br />
              Sweden
            </Typography>
            <div className="grid-item">
              <img src="/images/ce/Symbol_MD.png" />
            </div>
            <div className="grid-item">
              <img src="/images/ce/REF-symbol.jpg" />
            </div>

            <Typography style={{ width: '100%', display: 'block', padding: '10px' }}>
              Healfy {publicRuntimeConfig.APP_VERSION}
            </Typography>
          </section>
        </aside>
      </Modal>
      <style jsx>{`
        .grid-item {
          display: flex;
          align-items: center;
          margin: 0 auto;
          height: 100%;
          width: 55px;
        }
        .grid-item img {
          display: block;
          width: 100%;
          height: 37px;
        }
        .logos-grid {
          display: grid;
          grid-template-columns: 60px 60px 1fr;
          margin: 15px 0;
        }
        .modal-wrapper {
          min-width: 400px;
          min-height: 300px;
          padding: 20px;
        }

        @media ${Breakpoints.SM} {
          .modal-wrapper {
            min-width: unset;
          }
        }
        .logo {
          display: block;
          height: 50px;
          width: 150px;
          margin: 0 auto;
        }
        .logo img {
          display: inline-block;
          width: 100%;
          height: auto;
          image-rendering: -webkit-optimize-contrast;
        }
      `}</style>
    </>
  );
};
