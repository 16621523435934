import { PatientsDetails } from 'nhi.shared/dist/models/patients-details';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import {
  GET_PATIENT_DETAILS,
  EDIT_PATIENT,
  GET_PATIENT_SESSION_WEIGHTS
} from '../../actions/patients/patients.actions';

export const initialState: PatientsDetails = {};

const patientsDetailsReducer = createReducer(initialState, {
  [GET_PATIENT_DETAILS]: (state, payload) => ({ ...state, ...payload }),
  [GET_PATIENT_SESSION_WEIGHTS]: (state, { latestResponseWeights, careRecipientId }) => {
    const details = state[careRecipientId];
    return {
      ...state,
      [careRecipientId]: {
        ...details,
        latestResponseWeights
      }
    };
  },
  [EDIT_PATIENT]: (state, { userId, firstName, lastName, height, phoneNumber, email }) => {
    const details = state[userId];

    const updated = {
      ...details,
      person: {
        ...details.person,
        firstName,
        lastName,
        height,
        contactDetails: {
          ...details.person.contactDetails,
          phoneNo: phoneNumber,
          email
        }
      }
    };

    return { ...state, [userId]: updated };
  }
});

export default patientsDetailsReducer;
