import { Dispatch } from 'redux';

import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

import { selectCurrentUserId } from '../selectors/application.selectors';

export const GET_AVAILABLE_HEALTH_PACKAGES = 'GET_AVAILABLE_HEALTH_PACKAGES';
export const UPDATE_PATIENT_HEALTH_PACKAGES = 'UPDATE_PATIENT_HEALTH_PACKAGES';

const getAvailableHealthPackagesAction = createAction(GET_AVAILABLE_HEALTH_PACKAGES);
const updatePatientHealthPackagesAction = createAction(UPDATE_PATIENT_HEALTH_PACKAGES);

export const getAvailableHealthPackages = async (dispatch: Dispatch, getStore) => {
  const userId = selectCurrentUserId(getStore());

  try {
    const { result } = await apiFetch(dispatch)('/api/HealthPackage/GetAvailableHealthPackages', {
      userId
    });

    dispatch(getAvailableHealthPackagesAction(result));
  } catch {}
};

export const updatePatientHealthPackages = (params) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)(
      '/api/HealthPackage/AssignHealthPackagesToCareRecipient',
      params
    );

    dispatch(updatePatientHealthPackagesAction(result));
  } catch {}
};
