import { CustomerMeasurementLimits, PatientMeasurementsResponse } from 'nhi.shared/dist/models/measurements';
import { MeasurementLimit } from 'nhi.shared/dist/models/measurements';

import { MeasurementsLimits } from '../reducers/customers/measurementsLimits.reducer';

export const mapCustomerMeasurementsLimits = (customerLimits: CustomerMeasurementLimits[]) => {
  const result = customerLimits.reduce((acc, customer) => {
    const limits = mapMeasurementsLimits(customer.measurementLimits);
    return { ...acc, [customer.directoryId]: limits };
  }, {});

  return result;
};

export const mapUsersLatestMeasurements = (measurements: PatientMeasurementsResponse[]) => {
  return measurements.reduce((acc, u) => {
    acc[u.userId] = {
      ...u,
      measurementLimits: mapMeasurementsLimits(u.measurementLimits)
    };

    return acc;
  }, {});
};

export const mapMeasurementsLimits = (limits: MeasurementLimit[]): MeasurementsLimits => {
  return limits.reduce((newLimits, limit) => {
    const range = limit.measurementLimitType === 1 ? 'max' : 'min';

    if (!newLimits[limit.measurementTypeId]) {
      newLimits[limit.measurementTypeId] = {};
    }

    newLimits[limit.measurementTypeId][range] = limit.value;

    return newLimits;
  }, {});
};
