import { CustomMeasurementType } from 'nhi.shared/dist/models/custom-measurement-type';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import {
  GET_CUSTOM_MEASUREMENT_TYPES,
  ADD_CUSTOM_MEASUREMENT_TYPE,
  REMOVE_CUSTOM_MEASUREMENT_TYPE
} from '@store/actions/customers/measurementTypes.actions';

export type MeasurementTypesState = CustomMeasurementType[];

export const initialState: CustomMeasurementType[] = [];

const measurementTypesReducer = createReducer(initialState, {
  [GET_CUSTOM_MEASUREMENT_TYPES]: (_, payload) => payload as CustomMeasurementType[],
  [ADD_CUSTOM_MEASUREMENT_TYPE]: (state, payload) => {
    const mt = payload as CustomMeasurementType;
    if (!!mt.id) {
      return [...state, mt];
    }
    return state;
  },
  [REMOVE_CUSTOM_MEASUREMENT_TYPE]: (state, payload) => {
    const id = payload as string;
    if (!!id) {
      return [...state.filter(x => x.id !== id)];
    }
    return state;
  }
});

export default measurementTypesReducer;
