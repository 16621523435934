import { Dispatch } from 'redux';

import { UpdateAuthOptionsRequest } from 'nhi.shared/dist/models/update-auth-options';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

export const GET_AUTH_OPTIONS = 'GET_AUTH_OPTIONS';
export const EDIT_AUTH_OPTIONS = 'EDIT_AUTH_OPTIONS';
export const START_UPDATE_AUTH_OPTIONS = 'START_UPDATE_AUTH_OPTIONS';
export const CANCEL_EDIT_AUTH_OPTIONS = 'CANCEL_EDIT_AUTH_OPTIONS';
export const UPDATE_AUTH_OPTIONS_ERROR = 'UPDATE_AUTH_OPTIONS_ERROR';

export const UPDATE_AUTH_OPTIONS = 'UPDATE_AUTH_OPTIONS';

export const ADD_RFID = 'ADD_RFID';
export const REMOVE_RFID = 'REMOVE_RFID';

const getAuthOptionsAction = createAction(GET_AUTH_OPTIONS);
const editAuthOptionsAction = createAction(EDIT_AUTH_OPTIONS);
const startAuthOptionsAction = createAction(START_UPDATE_AUTH_OPTIONS);
const cancelEditAuthOptionsAction = createAction(CANCEL_EDIT_AUTH_OPTIONS);

const updateAuthOptionsAction = createAction(UPDATE_AUTH_OPTIONS);

const addRfidAction = createAction(ADD_RFID);
const removeRfidAction = createAction(REMOVE_RFID);

export const updateAuthOptions = (update: UpdateAuthOptionsRequest) => async (dispatch: Dispatch) => {
  dispatch(startAuthOptionsAction({ userId: update.userId }));

  try {
    const data = await apiFetch(dispatch)('/api/authOptions/updateAuthOptions', update);
    if (data?.result) {
      dispatch(
        updateAuthOptionsAction({
          userId: update.userId,
          authOptions: data.result
        })
      );
    }
  } catch (error) {
    dispatch(updateAuthOptionsAction({ userId: update.userId, exception: error }));
  }
};

export const cancelEditAuthOptions = (userId: string) => (dispatch) => {
  if (userId) {
    dispatch(cancelEditAuthOptionsAction({ userId }));
  }
};

export const getAuthOptions = (userId: string) => async (dispatch: Dispatch) => {
  const data = await apiFetch(dispatch)('/api/authOptions/getAuthOptions', { userId });
  if (data?.result) {
    dispatch(getAuthOptionsAction({ userId, authOptions: data.result }));
    dispatch(editAuthOptionsAction({ userId }));
  }
};

export const removeRfid = (userId: string, authOptionId: string) => (dispatch) => {
  if (authOptionId) {
    dispatch(removeRfidAction({ userId, authOptionId }));
  }
};

export const addRfid = (userId: string, tempId: string) => (dispatch) => {
  if (tempId && userId) {
    dispatch(addRfidAction({ tempId, userId }));
  }
};
