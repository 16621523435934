import { Dispatch } from 'redux';

import { AddOrdinationBody } from 'nhi.shared/dist/models/ordinations';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

import { selectCurrentUserId } from '../../selectors/application.selectors';

export const GET_ORDINATIONS = 'GET_ORDINATIONS';
export const ADD_ORDINATION = 'ADD_ORDINATION';
export const REMOVE_ORDINATION = 'REMOVE_ORDINATION';

const getOrdinationsAction = createAction(GET_ORDINATIONS);
const addOrdinationAction = createAction(ADD_ORDINATION);
const removeOrdinationAction = createAction(REMOVE_ORDINATION);

export const getOrdinations = (careRecipientId: string) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)('/api/MedicineOrdinate/GetMedicineOrdinates', {
      careRecipientId
    });
    dispatch(getOrdinationsAction({ careRecipientId, ordinations: result }));
  } catch {}
};

type AddOrdinationParams = Omit<AddOrdinationBody, 'caregiverId'>;

export const addOrdination = (params: AddOrdinationParams) => async (dispatch, getStore) => {
  const caregiverId = selectCurrentUserId(getStore());

  return apiFetch(dispatch)('/api/MedicineOrdinate/AddMedicineOrdinate', { caregiverId, ...params }).then(
    ({ result }) =>
      dispatch(
        addOrdinationAction({
          ordination: result,
          careRecipientId: params.careRecipientId
        })
      )
  );
};

export const removeOrdination = (ordinationId: string, careRecipientId: string) => async (dispatch) => {
  try {
    await apiFetch(dispatch)('api/MedicineOrdinate/RemoveMedicineOrdinate', {
      careRecipientId,
      id: ordinationId
    });
    dispatch(
      removeOrdinationAction({
        ordinationId,
        careRecipientId
      })
    );
  } catch {}
};
