import { PhotoReport } from 'nhi.shared/dist/models/patient-session';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import { GET_PHOTO_REPORT, CLEAR_PHOTO_REPORT } from '../../actions/patients/patients.actions';

export const initialState: PhotoReport = null;

const patientsSessionsReducer = createReducer(initialState, {
  [GET_PHOTO_REPORT]: (_, data) => data as PhotoReport,
  [CLEAR_PHOTO_REPORT]: _ => null
});

export default patientsSessionsReducer;
