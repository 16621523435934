import { Dispatch } from 'redux';

import { Notification } from 'nhi.shared/dist/models/notification';
import { mapNotifications } from 'nhi.shared/dist/utils/notifications';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

export const TOGGLE_VIDEO_CHAT = 'TOGGLE_VIDEO_CHAT';
export const SET_ACTIVE_PEERS = 'SET_ACTIVE_PEERS';
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';
export const NOTIFICATIONS_MARK_AS_READ = 'NOTIFICATIONS_MARK_AS_READ';
export const SET_SOCKET_STREAM = 'SET_SOCKET_STREAM';

export const toggleVideoChatAction = createAction(TOGGLE_VIDEO_CHAT);
export const setActivePeersAction = createAction(SET_ACTIVE_PEERS);
export const setSocketStream = createAction(SET_SOCKET_STREAM);
export const getAllNotificationsAction = createAction(GET_ALL_NOTIFICATIONS);
export const markNotificationAsReadAction = createAction(NOTIFICATIONS_MARK_AS_READ);
export const notificationReceivedAction = createAction(NOTIFICATION_RECEIVED);

export const getAllNotifications = (userId: string) => async (dispatch: Dispatch) => {
  try {
    const data = await apiFetch(dispatch)<Notification[]>('/api/Notifications/GetAllNotifications', {
      recipientId: userId
    });
    const mapped = mapNotifications(data.result);
    dispatch(getAllNotificationsAction(mapped));
  } catch {}
};

export const markNotificationAsRead = (notificationIds: string[]) => async (dispatch: Dispatch) => {
  try {
    await apiFetch(dispatch)<Notification[]>('/api/Notifications/ReadNotification', {
      notificationIds
    });

    dispatch(markNotificationAsReadAction(notificationIds));
  } catch {}
};
