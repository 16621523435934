import { FiMessageSquare } from '@react-icons/all-files/fi/FiMessageSquare';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Badge from 'nhi.shared/dist/components/atoms/Badge';
import theme from 'nhi.shared/dist/config/theme';

import { getConversationsHistory } from '@store/actions/core/conversations.actions';
import { selectCurrentUserId } from '@store/selectors/application.selectors';
import { selectConversations } from '@store/selectors/conversations.selectors';

import { routes } from '../../config/routes';

export const MessageCenterIcon = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const iconColor = router.pathname === routes.messages ? theme.secondary : theme.icons;

  const [hasNewMessage, setHasNewMessage] = useState<boolean>(false);

  const conversations = useSelector(selectConversations);
  const currentUserId = useSelector(selectCurrentUserId);

  useEffect(() => {
    dispatch(getConversationsHistory());
  }, []);

  useEffect(() => {
    setHasNewMessage(
      conversations?.some(c => c.messages.some(m => m.senderId !== currentUserId && !m.isRead)) || false
    );
  }, [conversations]);

  return (
    <Link href={routes.messages}>
      <a>
        <Badge isVisible={hasNewMessage}>
          <FiMessageSquare color={iconColor} fontSize="20px" />
        </Badge>
      </a>
    </Link>
  );
};
