import { Dispatch } from 'redux';

import { CustomMeasurementType } from 'nhi.shared/dist/models/custom-measurement-type';
import { createAction } from 'nhi.shared/dist/utils/redux';

import { apiFetch } from '@lib/api';

import { showSnackbarAction } from '@store/actions/core/application.actions';


export const GET_CUSTOM_MEASUREMENT_TYPES = 'GET_CUSTOM_MEASUREMENT_TYPES';
export const ADD_CUSTOM_MEASUREMENT_TYPE = 'ADD_CUSTOM_MEASUREMENT_TYPE';
export const REMOVE_CUSTOM_MEASUREMENT_TYPE = 'REMOVE_CUSTOM_MEASUREMENT_TYPE';

const getCustomMeasurementTypesAction = createAction(GET_CUSTOM_MEASUREMENT_TYPES);
const addCustomMeasurementTypeAction = createAction(ADD_CUSTOM_MEASUREMENT_TYPE);
const removeCustomMeasurementTypeAction = createAction(REMOVE_CUSTOM_MEASUREMENT_TYPE);

export const getCustomMeasurementTypes = (directoryId: string) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)<CustomMeasurementType[]>(
      '/api/measurement/GetCustomMeasurementTypes',
      {
        directoryId
      }
    );

    dispatch(getCustomMeasurementTypesAction(result));
  } catch {}
};

export const addCustomMeasurementType =
  (directoryId: string, name: string, unit: number, unitName?: string) => async (dispatch: Dispatch) => {
    try {
      const { result } = await apiFetch(dispatch)<CustomMeasurementType>(
        '/api/measurement/AddCustomerCustomMeasurementType',
        {
          name,
          directoryId,
          unit,
          customUnit: unitName
        }
      );

      dispatch(addCustomMeasurementTypeAction(result));
    } catch {
      dispatch(showSnackbarAction({ message: 'Type already exists', type: 'error', duration: 2500 }));
    }
  };

export const removeCustomMeasurementType = (id: string) => async (dispatch: Dispatch) => {
  try {
    const { result } = await apiFetch(dispatch)<{ customerCustomMeasurementTypeId: string }>(
      '/api/measurement/RemoveCustomerCustomMeasurementType',
      {
        customerCustomMeasurementTypeId: id
      }
    );

    dispatch(removeCustomMeasurementTypeAction(result?.customerCustomMeasurementTypeId));
  } catch {
    dispatch(
      showSnackbarAction({
        message: 'Could not remove measurement type. Consider checking access',
        type: 'error',
        duration: 2500
      })
    );
  }
};
