import { HubConnection } from '@microsoft/signalr';

import { Dictionary } from 'nhi.shared/dist/models/common';
import { PeerItems } from 'nhi.shared/dist/models/communication';
import { Notification } from 'nhi.shared/dist/models/notification';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import {
  TOGGLE_VIDEO_CHAT,
  SET_ACTIVE_PEERS,
  GET_ALL_NOTIFICATIONS,
  NOTIFICATION_RECEIVED,
  NOTIFICATIONS_MARK_AS_READ,
  SET_SOCKET_STREAM
} from '../../actions/core/communication.actions';

export interface CommunicationState {
  videoChat: boolean;
  peers: PeerItems;
  notifications?: Notification[];
  socketStreams: Dictionary<HubConnection>;
}

export const initialState: CommunicationState = {
  videoChat: false,
  peers: {},
  socketStreams: {}
};

const communicationReducer = createReducer(initialState, {
  [TOGGLE_VIDEO_CHAT]: (state) => ({ ...state, videoChat: !state.videoChat }),
  [SET_ACTIVE_PEERS]: (state, peers) => ({ ...state, peers }),
  [SET_SOCKET_STREAM]: (state, { key, connection }) => ({
    ...state,
    socketStreams: {
      ...state.socketStreams,
      [key]: connection as HubConnection
    }
  }),
  [GET_ALL_NOTIFICATIONS]: (state, notifications) => ({
    ...state,
    notifications: notifications
  }),
  [NOTIFICATION_RECEIVED]: (state, notification) => {
    const notifications = state.notifications ?? [];

    return {
      ...state,
      notifications: [notification, ...notifications]
    };
  },
  [NOTIFICATIONS_MARK_AS_READ]: (state, notificationIds) => ({
    ...state,
    notifications: [
      ...state.notifications.map((n) =>
        notificationIds.find((x) => x == n.notificationId) ? { ...n, isRead: true } : n
      )
    ]
  })
});

export default communicationReducer;
