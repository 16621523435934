import { Conversation } from 'nhi.shared/dist/models/conversation';
import { addMessageToConversations } from 'nhi.shared/dist/utils/messages';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import {
  GET_CONVERSATIONS_HISTORY,
  MESSAGE_RECEIVED,
  READ_CONVERSATION
} from '../../actions/core/conversations.actions';

export const initialState: Conversation[] = [];

const conversationsReducer = createReducer(initialState, {
  [GET_CONVERSATIONS_HISTORY]: (_, payload) => payload,
  [MESSAGE_RECEIVED]: (state, conversation: Conversation) => addMessageToConversations(state, conversation),
  [READ_CONVERSATION]: (state, { conversationId, currentUserId }) =>
    state.map(c => {
      if (c.id === conversationId) {
        c.messages = c.messages.map(m => {
          m.isRead = m.senderId !== currentUserId;
          return m;
        });
      }
      return c;
    })
});

export default conversationsReducer;
