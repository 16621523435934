import { useTranslation } from 'react-i18next';

import Button from 'nhi.shared/dist/components/atoms/Button';
import Flex from 'nhi.shared/dist/components/atoms/Flex';

export const ITEMS_PER_PAGE = 12;

const Pagination = ({ totalItems, availableItems, page, setActivePage }) => {
  if (availableItems.length >= totalItems) {
    return null;
  }

  const { t } = useTranslation();

  const previousPage = () => {
    if (page !== 0) {
      setActivePage(page - 1);
    }
  };
  const nextPage = () => {
    if (page < totalItems / ITEMS_PER_PAGE) {
      setActivePage(page + 1);
    }
  };
  const setPage = pageNumber => () => setActivePage(pageNumber);

  const prevButtonDisabled = page === 0;
  const nextButtonDisabled = page + 1 >= totalItems / ITEMS_PER_PAGE;

  return (
    <Flex.Container justify="center">
      <Button
        color={prevButtonDisabled ? 'gray5' : 'secondary'}
        size="small"
        disabled={prevButtonDisabled}
        onClick={previousPage}
      >
        {t('Previous')}
      </Button>
      {Array.from(Array(Math.ceil(totalItems / ITEMS_PER_PAGE)).keys()).map(pageNumber => {
        return (
          <Button
            key={`page_button_${pageNumber}`}
            size="small"
            htmlColor={pageNumber === page ? '#00BBA0' : '#000'}
            ghost
            disabled={pageNumber === page}
            onClick={setPage(pageNumber)}
          >
            {pageNumber + 1}
          </Button>
        );
      })}
      <Button
        color={nextButtonDisabled ? 'gray5' : 'secondary'}
        size="small"
        disabled={nextButtonDisabled}
        onClick={nextPage}
      >
        {t('Next')}
      </Button>
    </Flex.Container>
  );
};

export default Pagination;
