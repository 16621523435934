import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import sv from './sv.json';
export const defaultNS = 'translation';
export const resources = {
  en: {
    translation: en
  },
  sv: {
    translation: sv
  }
} as const;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'sv',
    resources,
    fallbackLng: 'en',
    ns: ['translation'],
    defaultNS,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

const localize = i18n;

export default localize;
