import {
  HomeMonitoringHistory,
  HomeMonitoringInfo,
  HomeMonitoringQuestion,
  HomeMonitoringSummary
} from 'nhi.shared/dist/models/home-monitoring';
import { createReducer } from 'nhi.shared/dist/utils/redux';

import {
  GET_QUESTIONS,
  GET_INITIAL_INFORMATION,
  GET_HOME_MONITORING_HISTORY,
  GET_HOME_MONITORING_PLAN
} from '../actions/home-monitoring.actions';

export interface HomeMonitoringState {
  info: HomeMonitoringInfo;
  questions: HomeMonitoringQuestion[];
  isLoading: boolean;
  history: Record<string, HomeMonitoringHistory[]>;
  details: Record<string, HomeMonitoringSummary>;
}

export const initialState: HomeMonitoringState = {
  info: {
    diseases: [],
    communicationChannels: [],
    careRecipient: null,
    limitExceedingActionTypes: [],
    measurementDevices: [],
    relationTypes: [],
    specificNeedTypes: []
  },
  questions: null,
  history: {},
  details: {},
  isLoading: true
};

const homeMonitoringReducer = createReducer(initialState, {
  [GET_QUESTIONS]: (state, { questions }) => ({ ...state, questions }),
  [GET_INITIAL_INFORMATION]: (state, info) => ({ ...state, info }),
  [GET_HOME_MONITORING_PLAN]: (state, plan) => ({
    ...state,
    details: {
      ...state.details,
      [plan.id]: plan
    }
  }),
  [GET_HOME_MONITORING_HISTORY]: (state, { careRecipientId, homeMonitoringPlans }) => ({
    ...state,
    history: {
      ...state.history,
      [careRecipientId]: homeMonitoringPlans
    }
  })
});

export default homeMonitoringReducer;
