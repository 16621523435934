export const routes = {
  dashboard: '/dashboard',
  messages: '/messages',
  patients: '/patients',
  patientDetails: '/patients/[patientId]',
  patientRegistration: '/patient-registration',
  measurements: '/',
  accessLogs: '/access-logs',
  settings: '/settings',
  login: '/login',
  loginSiths: '/login/siths',
  signOut: '/sign-out',
  404: '/404'
};

export const layoutRoutes = [
  routes.dashboard,
  routes.measurements,
  routes.patients,
  routes.patientDetails,
  routes.settings,
  routes.messages,
  routes.patientRegistration,
  routes.accessLogs
];
